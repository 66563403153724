import React from 'react';
import PermissionToggle from 'components/permission/PermissionToggle';
import {makeStyles} from '@material-ui/core';
import IconButton from 'components/buttons/IconButton';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

function UsersTaskButton(props) {
  const {setDrawer} = props;

  const useStyles = makeStyles((theme) => ({
    icon: {
      fontSize: 28,
      [theme.breakpoints.down("1300")]: {
        fontSize: 22,
      },
      [theme.breakpoints.down("1100")]: {
        fontSize: 20,
      },
    },
    badge: {
      background: theme.palette.success.main,
    },
    moreIconsButtons: {
      color: 'rgba(0,0,0,0.6) !important',
      fontSize: '1.25rem',
      '& span': {
        '& svg': {
          marginRight: 10,
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <PermissionToggle permissionKey="leadFollowUpSales" hasFeedback={false} >
      <IconButton 
        alt="Tarefas" 
        handleClick={() => setDrawer(true)} 
        data-tour="tour_home_menu_online"
        className={classes.moreIconsButtons}
      >
        <AssignmentTurnedInIcon className={classes.icon} />
        Tarefas
      </IconButton>
    </PermissionToggle>
  );
}

export default UsersTaskButton;
