const initialState = {
  uploadSpeedAverage: 0,
  downloadSpeedAverage: 0,
  attachments: [], // attachments on commands chat
  conversationMessages: null,
  conversationLoading: true, // conversation list loading
  conversationLimit: 25, // limit to api request
  feedback: null,
  featureToggleList: [],
  leadList: [],
  loadingLeads: true, // lead list loading
  leadListLimit: 20, // limit to api request
  leadListFilterText: null,
  filteredAdditionalInfo: {},
  isLoginModalOpen: false,
  createLeadModal: false,
  createLeadDefaultValues: null,
  isRequesting: false, // loading indicator
  selectedLeadData: {},
  selectedLeadLoading: true, // lead view loading
  notFoundLead: false, // lead view catch
  channelList: [],
  statusList: [],
  stateList: [],
  saleList: [],
  listDocs: [],
  selectedDoc: [],
  additionalInformation: null,
  fieldConfig: null,
  toggleAddDoc: false,
  toggleEditDoc: false,
  toggleAddSigner: false,
  toggleSignature: false,
  mobileScreen: 'left', // active section on mobile
  accordionOpen: true, // middle section accordion  
  userTerms: false,
  usersOnline: [],
  isEmailOptinNotAllowed: false,
  isWhatsappOptinNotAllowed: false,
  isPhoneOptinNotAllowed: false,
  isContactOptinBlocked: false,
  userLGPD: {},
  trainingClassesForm: [],
  tourIsFirstTime: null,
  bestMatchListRefresh: false,
  // global voip dialer
  VOIP_DIALER_callStatus: 'off',
  voip_dialer_drawer: false,
  // global voip
  VOIP_isActiveComponent: false,
  VOIP_callStatus: 'off',
  VOIP_bina: null,
  VOIP_binaIsValid: null,
  VOIP_ramalNumber: null,
  VOIP_webphoneUrl: null,
  VOIP_PhonetoVoip: null,
  VOIP_idChamada: null,
  VOIP_ShowOnHeader: null,
  VOIP_LeadData: {},
  leadsWaitingOnPocket: [],
};

function resetAppFeedback(state) {
  return {...state, feedback: null};
}

function setAppFeedback(state, payload) {
  const {message, type} = payload;
  return {...state, feedback: {message, type}};
}

// TODO: keyPath
function setAppValue(state, payload) {
  const {key, value} = payload;
  return {...state, [key]: value};
}

/**
 * Set the state value at key with the value returned from callback.
 * This function is necessary when you need to work with some previous state or other state value
 * to generate this value. Examples are: push, filter, increment...
 *
 * @param state
 * @param {object} payload
 * @param {function} payload.cb This cb must return the value to be set at key into the state. The
 * param passed to this cb depends on whether the cbParamKey is provided or not. If cbParamKey is
 * present, then the param will be the value in that key at state. If not present then the param
 * will be the value at key.
 * @param {string|undefined} payload.cbParamKey
 * @param {string} payload.key
 */
function setAppValueWithCb(state, payload) {
  const {
    cb,
    key,
    cbParamKey = key,
  } = payload;
  const cbParam = state[cbParamKey];
  const newValue = cb(cbParam); // TODO: fazer copia se for array ou objeto
  return {...state, [key]: newValue};
}

// TODO: empty state
// TODO: unknown state
function setXhrErrorState(state) {
  return {...state, isRequesting: false};
}

function setXhrStartState(state) {
  return {...state, isRequesting: true};
}

function setXhrSuccessState(state) {
  return {...state, isRequesting: false};
}

export default function reducer(state = initialState, action = '') {
  switch (action.type) {
    case 'RESET_APP_FEEDBACK':
      return resetAppFeedback(state);
    case 'SET_APP_FEEDBACK':
      return setAppFeedback(state, action.payload);
    case 'SET_APP_VALUE':
      return setAppValue(state, action.payload);
    case 'SET_APP_VALUE_WITH_CB':
      return setAppValueWithCb(state, action.payload);
    case 'SET_XHR_ERROR_STATE':
      return setXhrErrorState(state);
    case 'SET_XHR_START_STATE':
      return setXhrStartState(state);
    case 'SET_XHR_SUCCESS_STATE':
      return setXhrSuccessState(state);
    default:
  }
  return state;
}
