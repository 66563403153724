import React, {useEffect, useState, Fragment} from "react";
import {Link, useLocation} from "react-router-dom";
import {useUserContext} from "context/index";
import {useDispatchPost} from "redux/hooks/fetch";
import {useAppValue, useDispatchSetAppValue} from "redux/hooks/app";
import PermissionToggle from "components/permission/PermissionToggle";
import useIsAllowed from "components/permission/useIsAllowed";
import {useThemeContext} from "context";

/* MATERIAL */
import {makeStyles} from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import AddCircle from "@material-ui/icons/AddCircle";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {Badge, Button} from "@material-ui/core";
import IconButton from "components/buttons/IconButton";
import Dropdown from "components/dropdown/Dropdown";
import RegularDropdown from 'components/Dropdown';
import Drawer from "components/drawer/Drawer";
import PerfilBar from "./PerfilBar";
import ChartsMenuDropdown from "./ChartsMenuDropdown";
import NotificationButton from "./NotificationButton";
import UsersOnlineButton from "./UsersOnlineButton";
import UsersTaskButton from "./UsersTaskButton";
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PocketOfLeadsButton from "./PocketOfLeadsButton";
import UserTermsAcceptModal from "routes/terms-user/UserTermsAcceptModal";
import NotificationAcceptModal from "routes/notification-center/notificationAcceptModal";
import FeatureToggle from "components/FeatureToggle";
import VoIpHeader from "components/VoIpHeader";
import FavoriteLeadsButton from "./FavoriteLeadsButton";
import UsersTask from "./UsersTask";
import UsersOnline from "./UsersOnline";
import FavoriteLeads from "./FavoriteLeads";

function Header() {
  const VoipIsActive = useAppValue('VOIP_isActiveComponent');

  const {pathname} = useLocation();
  const voipEmbedded = pathname.includes('/webphone');

  const useStyles = makeStyles((theme) => {
    return {
      grow: {
        flexGrow: 1,
      },
      homeLink: {
        width: 133,
      },
      logo: {
        height: 36,
        display: loadLogo ? "block" : "none",
      },
      appBar: {
        display: voipEmbedded ? 'none' : 'block',
        cursor: VoipIsActive ? 'not-allowed' : 'initial',
        height: theme.heights.header,
        backgroundColor: theme.palette.supportThree,
        "& > div": {
          height: "100%",
          pointerEvents: VoipIsActive ? 'none' : 'initial',
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
      },
      menu: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        [theme.breakpoints.down("1100")]: {
          marginRight: theme.spacing(3),
          marginLeft: theme.spacing(3),
        },
        flexGrow: 1,
        "& > *": {
          marginRight: 70,
          [theme.breakpoints.down("1580")]: {
            marginRight: 20,
          },
          [theme.breakpoints.down("1460")]: {
            marginRight: 20,
          },
          [theme.breakpoints.down("1380")]: {
            marginRight: 0,
          },
          [theme.breakpoints.down("1270")]: {
            marginRight: 0,
          },
        },
      },
      link: {
        color: theme.palette.supportTwo,
      },
      icon: {
        fontSize: 28,
        [theme.breakpoints.down("1300")]: {
          fontSize: 22,
        },
        [theme.breakpoints.down("1100")]: {
          fontSize: 20,
        },
      },
      sectionDesktop: {
        display: "none",
        flexGrow: 1,
        [theme.breakpoints.up("1000")]: {
          display: "flex",
          alignItems: "center",
        },
        "& button, a": {
          color: theme.palette.supportOne,
        },
      },
      textButton: {
        fontFamily: "Montserrat",
        textTransform: "capitalize",
        pointerEvents: VoipIsActive ? 'none' : 'initial',
        fontSize: 14,
        [theme.breakpoints.down("1300")]: {
          fontSize: 12,
        },
      },
      clickableButton: {
        pointerEvents: VoipIsActive ? 'none' : 'initial',
      },
      moreIconsButtons: {
        pointerEvents: VoipIsActive ? 'none' : 'initial',
        color: 'rgba(0,0,0,0.6) !important',
        fontSize: '1.25rem',
        '& span': {
          '& svg': {
            marginRight: 10,
          },
        },
      },
      sectionMobile: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up("1000")]: {
          display: "none",
        },
        "& > button": {
          color: theme.palette.supportOne,
          marginRight: theme.spacing(2),
        },
        "& :nth-child(3)": {
          marginLeft: "auto",
          marginRight: 0,
        },
      },
      leadData: {
        margin: "auto",
        color: theme.palette.supportOne,
        textAlign: "center",
        width: "70%",
        "& > div:nth-child(1)": {
          fontSize: 17,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      middleSectionTitle: {
        color: theme.palette.supportOne,
        width: "100%",
        textAlign: "center",
      },
      moreIconsDropdown: {
        marginLeft: 10,
        "& > button": {
          border: 'none',
          borderRadius: '50%',
          padding: 0,
        },
        "& > div": {
          overflow: 'hidden',
          zIndex: 9999,
        },
      },
    };
  });

  const {
    clientId,
    isAdmin: userIsAdmin,
    // groupType: userGroupType,
    // manager: userManager,
    canImportLeadsExcel,
  } = useUserContext();
  const setClient = useDispatchSetAppValue("clientData");
  const client = useAppValue("clientData");

  const usersOnline = useAppValue('usersOnline');

  const isUserAllowedToRedistribute = useIsAllowed('redistribution');

  const {_id, name, statusDescription} = useAppValue("selectedLeadData");
  const {isMobile} = useThemeContext();
  const mobileScreen = useAppValue("mobileScreen");
  const setMobileScreen = useDispatchSetAppValue("mobileScreen");

  const [loadLogo, setLoadLogo] = useState(false);

  const [drawerPerfil, setDrawerPerfil] = useState(false);
  const [tasksDrawer, setTasksDrawer] = useState(false);
  const [onlineUsersDrawer, setOnlineUsersDrawer] = useState(false);
  const [favoriteLeadsDrawer, setFavoriteLeadsDrawer] = useState(false);

  const [sellerAdminList, setSellerAdminList] = useState([]);

  const [networkState, setNetworkState] = useState();

  const post = useDispatchPost();

  // connection speed indicator
  useEffect(() => {
    let interval = null;
    if (navigator && (navigator.onLine || navigator.connection)) {
      let downlink = navigator.connection ? navigator.connection.downlink : null;
      if (!navigator.onLine) setNetworkState({isConnected: navigator.onLine});
      else setNetworkState({isConnected: navigator.onLine, downlink});
      interval = setInterval(() => {
        downlink = navigator.connection ? navigator.connection.downlink : null;
        const action = pathname.split('/')[1] || 'home';
        if (!navigator.onLine) {
          setNetworkState((old) => {
            // check old value
            if (old.isConnected === true) {
              // disconnect
              localStorage.setItem('disconnect', JSON.stringify({disconnectedAt: new Date()}));
            }
            return {isConnected: navigator.onLine, downlink};
          })
        } else {
          let disconnect = null;
          let changeSpeed = false;
          setNetworkState((old) => {
            // check old value
            if (old.isConnected === false) {
              // reconnect
              disconnect = JSON.parse(localStorage.getItem('disconnect'));
              localStorage.removeItem('disconnect');
            }
            if (old !== downlink) {
              changeSpeed = true;
            }
            return {isConnected: navigator.onLine, downlink};
          })
          // store network data only when there is a change
          if (disconnect || changeSpeed) {
            // log network
            post('/network-monitoring/create', {
              action,
              disconnectedAt: disconnect ? disconnect.disconnectedAt : null,
              downloadSpeed: downlink,
              userAgent: navigator.userAgent,
              occurredAt: new Date(),
            })
              .then(() => { })
              .catch(() => { });
          }
        };
      }, [10000]);
    }
    return () => { if (interval) clearInterval(interval) }; // clear
  }, [pathname, post]);

  useEffect(() => {
    if (!clientId) return; // TODO: tirar isso quando o contexto só atualizar uma vez
    post("/client/view", {id: clientId}).then((responseData) => {
      setClient(responseData.data);
      function gtag() { window.dataLayer.push(arguments); }
      gtag('set', 'user_properties', {
        tenantId: responseData.data._id,
        tenant: responseData.data.name,
      });
    });
  }, [clientId, post, setClient]);

  useEffect(() => {
    let aux = [];
    if (isUserAllowedToRedistribute) {
      aux.push({
        label: "Redistribuição de Leads",
        link: "/redistribution/list",
        disabled: !isUserAllowedToRedistribute,
      })
    }
    if (canImportLeadsExcel) {
      aux.push({
        label: "Importação - planilha",
        link: "/import",
        disabled: !canImportLeadsExcel,
      })
    }
    // console.log('aux: ', aux);
    setSellerAdminList(aux);
  }, [canImportLeadsExcel, isUserAllowedToRedistribute])

  const classes = useStyles();

  const settingsList = [
    {
      label: "Configurações",
      link: "/settings",
      disabled: !useIsAllowed("settings"),
    },
    {
      label: "Integrações",
      link: "/integration/list",
      disabled: !useIsAllowed("integration"),
    },
    {
      label: "MegaDash",
      link: "/megadash/list",
      disabled: !useIsAllowed("settingsRestricted"),
    },
    {label: "Hubs", link: "/hub/list", disabled: !useIsAllowed("hub")},
    {label: "Grupos", link: "/group/list", disabled: !useIsAllowed("group")},
    {label: "PDV's", link: "/store/list", disabled: !useIsAllowed("hub")},
    {label: "Equipes", link: "/team/list", disabled: !useIsAllowed("teams")},
    {
      label: "Usuários",
      link: "/user/list",
      disabled: !useIsAllowed("setupMenu"),
    },
  ];

  const adminList = [
    {
      label: "Indicadores de Uso",
      link: "/platform-indicators",
      disabled: !useIsAllowed("indicators"),
    },
    {
      label: "Importação - Planilha",
      link: "/import",
      disabled: !useIsAllowed("leadImport"),
    },
    {
      label: "Atualizar Leads - planilha",
      link: "/lead/update",
      disabled: !useIsAllowed("leadImport"),
    },
    {
      label: "Treinamentos",
      link: "/training-admin/list",
      disabled: !useIsAllowed("trainingAdmin"),
    },
    {
      label: "Central de Tarefas e Notas",
      link: "/task/create-multiple",
      disabled: !useIsAllowed("hub"),
    },
    {
      label: "Base de Conhecimento",
      link: "/article/list",
      disabled: !useIsAllowed("hub"),
    },
    {
      label: "Central de Notificações",
      link: "/notification-center/list",
      disabled: !useIsAllowed("store"),
    },
    {
      label: "Escala de Atendimento",
      link: "/timetable/list",
      disabled: !useIsAllowed("association"),
    },
    {
      label: "Redistribuição de Leads",
      link: "/redistribution/list",
      disabled: !useIsAllowed("redistribution"),
    },
    {
      label: "Importações de Leads",
      link: "/import/list",
      disabled: !useIsAllowed("leadImport"),
    },
    {
      label: "Remoção de Leads",
      link: "/delete-leads/list",
      disabled: !useIsAllowed("nimbusAdmin"),
    },
    {
      label: "Uso de canais de comunicação",
      link: "/channel-use/",
      disabled: !useIsAllowed("nimbusAdmin"),
    },
    {
      label: "Pesquisa com usuário",
      link: "/survey/list/",
      disabled: !useIsAllowed("hub"),
    },
  ];

  const campaignList = [
    {
      label: "Minhas campanhas",
      link: "/campaign/list",
      disabled: !useIsAllowed("campaign"),
    },
    {
      label: "Meus templates de e-mail",
      link: "/campaign/email-template/",
      disabled: !useIsAllowed("campaign"),
    },
    {
      label: "Meus e-mails cadastrados",
      link: "/campaign/email-senders/",
      disabled: !useIsAllowed("campaign"),
    },
  ];

  const handlerDrawer = (isToShow) => {
    if (VoipIsActive) return false

    setDrawerPerfil(isToShow)
    function gtag() { window.dataLayer.push(arguments); }
    gtag('event', 'open_perfil');
  };

  if (
    pathname.indexOf("login") !== -1 ||
    pathname.indexOf("password-recover") !== -1 ||
    pathname.indexOf("privacy") !== -1
  )
    return null;

  return (
    <Fragment>
      {(networkState && (!networkState.isConnected || (networkState.downlink && networkState.downlink < 1))) && (
        <div
          style={{
            position: 'absolute',
            height: 18,
            width: '100%',
            background: networkState.isConnected ? 'orange' : '#c45757',
            textAlign: 'center',
            fontSize: 14,
          }}
        >
          {networkState.isConnected
            ? "Detectamos que sua conexão está lenta, você poderá ter problemas ao efetuar chamadas"
            : "Verifique sua conexão com a Internet"
          }
        </div>
      )}
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.sectionDesktop}>
            <Link className={classes.homeLink} to="/">
              {!loadLogo && <Skeleton variant="rect" width={133} height={40} />}
              <img
                src={client && client.logo}
                className={classes.logo}
                onLoad={() => setLoadLogo(true)}
                alt="logo"
              />
            </Link>
            <div className={classes.menu}>
              <Button
                className={classes.textButton}
                data-tour="tour_home_menu_home"
              >
                <Link to="/">Home</Link>
              </Button>
              <Button
                className={classes.textButton}
                data-tour="tour_home_menu_atendimento"
              >
                <Link to="/chat">Atendimento</Link>
              </Button>
              <ChartsMenuDropdown />
              <PermissionToggle permissionKey="store" hasFeedback={false}>
                <Dropdown
                  hasArrow={true}
                  label="Admin"
                  className={classes.textButton}
                  itensList={adminList}
                  dataTour="tour_home_menu_admin"
                />
              </PermissionToggle>
              {/** For users who are not admin, but can redistribute leads or import leads */}
              {
                !userIsAdmin &&
                sellerAdminList && sellerAdminList.length > 0 && (
                  <Dropdown
                    hasArrow={true}
                    label="Admin"
                    className={classes.textButton}
                    itensList={sellerAdminList}
                    dataTour="tour_home_menu_admin"
                  />
                )
              }
              <Button className={classes.textButton}>
                <Link to="/training/list">Treinamentos</Link>
              </Button>
              <FeatureToggle configKey="campaignModule">
                <PermissionToggle permissionKey="campaign" hasFeedback={false}>
                  <Badge badgeContent={"Beta"} color="primary">
                    <Dropdown
                      hasArrow={true}
                      label="Campanhas"
                      className={classes.textButton}
                      itensList={campaignList}
                    />
                  </Badge>
                </PermissionToggle>
              </FeatureToggle>
            </div>
            <VoIpHeader />

            <PocketOfLeadsButton />

            <PermissionToggle permissionKey="setupMenu" hasFeedback={false}>
              <Dropdown
                className={classes.clickableButton}
                label={
                  <SettingsIcon
                    className={classes.icon}
                    data-tour="tour_home_menu_config"
                  />
                }
                itensList={settingsList}
              />
            </PermissionToggle>

            <NotificationButton />

            <RegularDropdown
              className={classes.moreIconsDropdown}
              hasArrow={false}
              label={<AddCircle />}
              positionX="right"
              autoClosing={false}
            >
              {() => {
                return (
                  <div>
                    <div>
                      <IconButton
                        className={classes.moreIconsButtons}
                        alt="Perfil"
                        handleClick={() => handlerDrawer(true)}
                        data-tour="tour_home_menu_perfil"
                        style={{borderRadius: 0}}
                      >
                        <AccountCircle className={classes.icon} />
                        Perfil
                      </IconButton>
                      <hr />
                    </div>
                    <div>
                      <UsersTaskButton drawer={tasksDrawer} setDrawer={setTasksDrawer} />
                    </div>
                    <div>
                      <UsersOnlineButton drawer={onlineUsersDrawer} setDrawer={setOnlineUsersDrawer} />
                    </div>
                    <div>
                      <FavoriteLeadsButton drawer={favoriteLeadsDrawer} setDrawer={setFavoriteLeadsDrawer} />
                    </div>
                  </div>
                )
              }}
            </RegularDropdown>
            {/* perfil drawer */}
            <Drawer
              title="Perfil"
              direction={isMobile ? "left" : "right"}
              isOpen={drawerPerfil}
              onClose={() => handlerDrawer(false)}
            >
              <PerfilBar handleClose={() => handlerDrawer(false)} />
            </Drawer>
            {/* tasks drawer */}
            <Drawer title={`Tarefas`} direction={"right"} isOpen={tasksDrawer} onClose={() => setTasksDrawer(false)}>
              <UsersTask onClose={() => setTasksDrawer(false)} />
            </Drawer>
            {/* online users drawer */}
            <Drawer title={`Usuários Online (${usersOnline.length})`} direction={"right"} isOpen={onlineUsersDrawer} onClose={() => setOnlineUsersDrawer(false)}>
              <UsersOnline onClose={() => onlineUsersDrawer(false)} />
            </Drawer>
            {/* favorite leads drawer */}
            <Drawer title={`Leads favoritos`} direction={"right"} isOpen={favoriteLeadsDrawer} onClose={() => setFavoriteLeadsDrawer(false)}>
              <FavoriteLeads onClose={() => favoriteLeadsDrawer(false)} />
            </Drawer>
          </div>
          <div className={classes.sectionMobile}>
            {mobileScreen === "left" && (
              <Fragment>
                <IconButton handleClick={() => handlerDrawer(true)}>
                  <MenuIcon />
                </IconButton>
                <Link className={classes.homeLink} to="/chat">
                  {!loadLogo && (
                    <Skeleton variant="rect" width={133} height={40} />
                  )}
                  <img
                    src={client && client.logo}
                    className={classes.logo}
                    onLoad={() => setLoadLogo(true)}
                    alt="logo"
                  />
                </Link>
                <NotificationButton />
              </Fragment>
            )}
            {mobileScreen === "right" && (
              <Fragment>
                <IconButton handleClick={() => setMobileScreen("left")}>
                  <KeyboardBackspaceIcon />
                </IconButton>
                <div className={classes.leadData}>
                  <div>{name}</div>
                  <div>{statusDescription}</div>
                </div>
                <IconButton
                  handleClick={() => {
                    setMobileScreen("middle");
                    window.history.pushState(
                      null,
                      document.title,
                      `${window.location.origin}/chat/${_id}`,
                    );
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Fragment>
            )}
            {mobileScreen === "middle" && (
              <Fragment>
                <IconButton handleClick={() => setMobileScreen("right")}>
                  <KeyboardBackspaceIcon />
                </IconButton>
                <div className={classes.middleSectionTitle}>
                  Informações do Lead
                </div>
              </Fragment>
            )}
          </div>
          <UserTermsAcceptModal />
          <NotificationAcceptModal />
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

export default Header;
