import React, {Fragment, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';
import {useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchGet, useDispatchPost} from 'redux/hooks/fetch';
import FeedbackMessage from 'components/FeedbackMessage';
import {useUserContext} from 'context/index';
import LoginModal from 'frame/LoginModal';
import Header from 'header/Header';

function RouteWrapper(props) {
  const {route} = props;

  const {isAuthenticationChecked, clientId} = useUserContext();

  const {pathname} = useLocation();

  const setLeadList = useDispatchSetAppValue('leadList');
  const setLeadLoading = useDispatchSetAppValue('loadingLeads');
  const setSelectedLeadData = useDispatchSetAppValue('selectedLeadData');
  const setSelectedLeadLoading = useDispatchSetAppValue('selectedLeadLoading');
  const setConversationMessages = useDispatchSetAppValue('conversationMessages');
  const setConversationLoading = useDispatchSetAppValue('conversationLoading');

  const get = useDispatchGet();
  const setChannelList = useDispatchSetAppValue('channelList');
  const post = useDispatchPost();
  const setFeatureToggleList = useDispatchSetAppValue('featureToggleList');

  useEffect(() => {
    if (pathname.includes('/chat')) return;
    setLeadList([]);
    setLeadLoading(true)
    setSelectedLeadData({})
    setSelectedLeadLoading(true)
    setConversationMessages(null)
    setConversationLoading(true)
  }, [pathname, setConversationLoading, setConversationMessages, setLeadList, setLeadLoading, setSelectedLeadData, setSelectedLeadLoading]);

  useEffect(() => {
    if (!clientId) return;
    get('/channel/list').then((responseData) => {
      setChannelList(responseData.data);
    })
      .catch(() => { });
    post('/feature/client-list', {clientId, type: 'featureToggle'}).then((responseData) => {
      setFeatureToggleList(responseData.data);
    })
      .catch((e) => {
        console.log(e);
      });
  }, [clientId, get, post, setChannelList, setFeatureToggleList]);

  if (!isAuthenticationChecked) return null;
  return (
    <Fragment>
      <FeedbackMessage />
      <LoginModal />
      <Header />
      {renderRoutes(route.routes)}
    </Fragment>
  );
}

RouteWrapper.propTypes = {
  route: PropTypes.instanceOf(Object).isRequired,
};

export default RouteWrapper;
