import React, {Fragment, useEffect, useState} from 'react';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useSocketContext} from 'context';
import IconButton from 'components/buttons/IconButton';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import {makeStyles} from "@material-ui/core";
import Webphone from './voip/Webphone';
import FeatureToggle from './FeatureToggle';
import PermissionToggle from './permission/PermissionToggle';
import Drawer from './drawer/Drawer';
import VoipDialer from '../header/VoipDialer';

import customStyles from './VoIpHeader.module.css';

function VoIpHeader() {
  const {socketCore} = useSocketContext();

  const setVoipModal = useDispatchSetAppValue('voip_modal');
  const state = useAppValue('VOIP_callStatus');
  const voipDialerState = useAppValue('VOIP_DIALER_callStatus');

  const isTwilioVoip = useAppValue('is_twilio_voip_provider');

  const [voipDrawerOpen, setVoipDialerDrawer] = useState(false);

  const useStyles = makeStyles((theme) => ({
    voipIcon: {
      pointerEvents: 'initial',
      padding: 5,
      borderRadius: 20,
      color: !state || state === 'off' ? 'rgba(0, 0, 0, 0.87)' : '#E6E6E6',
      background:
        (state === 'onCall' || state === 'dialing') ? ('#217230') :
        ((!state || state === 'off') ? ('#F3F3F3') :
        ('#a99218')),
      fontSize: 19,
      [theme.breakpoints.down("1300")]: {
        fontSize: 13,
      },
      [theme.breakpoints.down("1100")]: {
        fontSize: 13,
      },
      transition: 'backgroung .5s',
    },
    voipDialerIcon: {
      pointerEvents: 'initial',
      padding: 5,
      borderRadius: 20,
      color: !voipDialerState || voipDialerState === 'off' ? 'rgba(0, 0, 0, 0.87)' : '#E6E6E6',
      background:
        (voipDialerState === 'onCall' || voipDialerState === 'dialing') ? ('#217230') :
        ((!voipDialerState || voipDialerState === 'off') ? ('#F3F3F3') :
        ('#a99218')),
      fontSize: 19,
      [theme.breakpoints.down("1300")]: {
        fontSize: 13,
      },
      [theme.breakpoints.down("1100")]: {
        fontSize: 13,
      },
      transition: 'backgroung .5s',
    },
  }));

  const styles = useStyles();

  // Voip Dialer call finished by lead state
  const setVoipDialerCurrentCallFinished = useDispatchSetAppValue('VOIP_DIALER_currentCallFinished');

  // Socket event - Only Dialer (Call finished - hang up by the lead)
  useEffect(() => {
    if (!socketCore) return;

    const handleCallFinished = () => {
      if (voipDialerState === 'onCall' || voipDialerState === 'dialing' || voipDialerState === 'ringing') {
        setVoipDialerCurrentCallFinished(true);
      }
    };

    socketCore.on('voip-dialer-call-finished', handleCallFinished);
    return () => {
      socketCore.removeListener('voip-dialer-call-finished', handleCallFinished);
    };
  }, [socketCore, setVoipDialerCurrentCallFinished, voipDialerState]);

  return (
    <Fragment>
      {/** Common voip */}
      {
        (state === 'onCall' || state === 'dialing' || state === 'ringing') && (
          <IconButton handleClick={() => setVoipModal(true)}>
            <CallOutlinedIcon className={styles.voipIcon} />
          </IconButton>
        )
      }
      <Webphone />
      {/** Voip Dialer */}
      <FeatureToggle configKey="voipDialer">
        <PermissionToggle permissionKey="voipDialer" hasFeedback={false}>
          {isTwilioVoip &&
            <Fragment>
              <IconButton
                handleClick={() => setVoipDialerDrawer(true)}
                className={(voipDialerState === 'onCall' || voipDialerState === 'dialing') ? customStyles.pulseEffect : ''}
                disabled={state === 'onCall' || state === 'dialing' || state === 'ringing'}
                alt="Discadora automática"
              >
                <CallOutlinedIcon className={styles.voipDialerIcon} />
              </IconButton>
              <Drawer
                title={"Discadora automática"}
                direction={"right"}
                isOpen={voipDrawerOpen}
                disableCloseButton={voipDialerState === 'dialing' || voipDialerState === 'ringing'} // User can`t close voip dialer while calling
                onClose={() => setVoipDialerDrawer(false)}
                enableBackdropInteraction={true}
              >
                <VoipDialer />
              </Drawer>
            </Fragment>
          }
          {!isTwilioVoip &&
            <Fragment></Fragment>
          }
        </PermissionToggle>
      </FeatureToggle>
    </Fragment>
  );
}

VoIpHeader.propTypes = {

};

export default VoIpHeader;
