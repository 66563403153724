import {setAppValue} from './app';

function setRequestError() { // TODO: acho que essas action sincronas precisam ir pro app
  // const state = {
  //   isRequesting: false,
  //   isXhrRunning: false,
  //   requestOperation: null,
  // };
  return {
    // state,
    type: 'SET_XHR_ERROR_STATE',
  };
}

export function setRequestStart() { // TODO: padronizacao entre request e XHR
  return {
    // state: {
    //   isRequesting: true,
    //   isXhrRunning,
    //   requestOperation,
    // },
    type: 'SET_XHR_START_STATE',
  };
}

function setRequestSuccess() {
  // const state = {
  //   isRequesting: false,
  //   isXhrRunning: false,
  //   requestOperation: null,
  // };
  return {
    // state,
    type: 'SET_XHR_SUCCESS_STATE',
  };
}

// Gambiarra
function resolveHost(url) {
  const env = window.ENV ?? process.env;
  const services = {
    communication: env.REACT_APP_DOMAIN_COMMUNICATION ?? 'http://localhost:60002',
    core: env.REACT_APP_DOMAIN_CORE ?? 'http://localhost:60000',
    setup: env.REACT_APP_DOMAIN_SETUP ?? 'http://localhost:60001',
    knowledge: env.REACT_APP_DOMAIN_KNOWLEDGE ?? 'http://localhost:60006',
    knowledgeDefault: process.env.NODE_ENV === 'production' ? 'https://knowledgesvc.default.nimbustarget.digital' : 'http://localhost:60010',
    storage: process.env.NODE_ENV === 'production' ? 'https://storagesvc.default.nimbustarget.digital' : 'http://localhost:60008',
  };

  // Starts with storage
  if (url.indexOf('/storage') === 0) return services.storage;
  if (url.indexOf('client') !== -1
    || url.indexOf('feature') !== -1
    || url.indexOf('workspace') !== -1
    || url.indexOf('pbi') !== -1
    || url.indexOf('report') !== -1) return services.setup;
  if (url.indexOf('conversation') !== -1
    || url.indexOf('channel') !== -1
    || url.indexOf('voip-ramal') !== -1
    || url.indexOf('voip-call') !== -1
    || url.indexOf('whatsapp-sender') !== -1
    || url.indexOf('email-senders') !== -1
    || url.indexOf('webchat') !== -1
    || url.indexOf('scheduled-message') !== -1)
    return services.communication;
  if (url.indexOf('topic') !== -1
    || url.startsWith('/training') === true
    || url.startsWith('/training-progress') === true
    || url.startsWith('/class') === true
    || url.indexOf('article') !== -1) return services.knowledge;
  if (url.startsWith('/platform-training') === true
    || url.startsWith('/platform-training-progress') === true
    || url.startsWith('/platform-class') === true) return services.knowledgeDefault;
  return services.core;
}

export function get(url) {
  return async (dispatch) => {
    try {
      dispatch(setRequestStart());
      const gambiarraHost = resolveHost(url);
      const response = await fetch(gambiarraHost + url, {
        headers: {
          Authorization: 'BEARER ' + localStorage.getItem('jwt'),
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      dispatch(setRequestSuccess());
      if (response.status === 401 && url !== '/user/login') {
        dispatch(setAppValue('isLoginModalOpen', true));
        // We can't pass new Error to this reject because React would show us
        // the red error screen of death. The only reason to use the reject
        // is to avoid run then() where it is called
        const responseText = response.text();
        return Promise.reject(responseText);
      }
      const parsedResponse = await response.json();
      if (!response.ok) return Promise.reject({message: parsedResponse.message, data: parsedResponse.data, status: response.status});
      return parsedResponse;
    } catch (e) {
      dispatch(setRequestError());
      return Promise.reject(new Error('Erro ao conectar com os servidores'));
    }
  };
}

// TODO: fazer condicao pra saber se é uma rota ou url de outro dominio
export function post(url, data) {
  return async (dispatch) => {
    try {
      dispatch(setRequestStart());
      const gambiarraHost = resolveHost(url);
      const response = await fetch(gambiarraHost + url, {
        body: JSON.stringify(data),
        headers: {
          Authorization: 'BEARER ' + localStorage.getItem('jwt'),
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
      dispatch(setRequestSuccess());
      if (response.status === 401 && url !== '/user/login') {
        dispatch(setAppValue('isLoginModalOpen', true));
        // We can't pass new Error to this reject because React would show us
        // the red error screen of death. The only reason to use the reject
        // is to avoid run then() where it is called
        const responseText = response.text();
        return Promise.reject(responseText);
      }
      const parsedResponse = await response.json();
      if (!response.ok) return Promise.reject({message: parsedResponse.message, data: parsedResponse.data, status: response.status});
      return parsedResponse;
    } catch (e) {
      dispatch(setRequestError());
      console.log('esse é o erro', e);
      return Promise.reject(new Error('Erro ao conectar com os servidores'));
    }
  };
}
