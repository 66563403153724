import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';
import additionalInformationRoutes from 'routes/additional-information/routes';
import fieldConfigRoutes from 'routes/Field-Configuration/routes';
import channelRoutes from 'routes/channel/routes';
import groupRoutes from 'routes/group/routes';
import hubRoutes from 'routes/hub/routes';
import megadashRoutes from 'routes/megadash/routes';
import intelDataRoutes from 'routes/intel-data/routes';
import importRoutes from 'routes/import/routes';
import leadRoutes from 'routes/lead/routes';
import storeRoutes from 'routes/store/routes';
import topicRoutes from 'routes/topic/routes';
import articleRoutes from 'routes/article/routes';
import settingsRoutes from 'routes/settings/routes';
import statusRoutes from 'routes/status/routes';
import pipelineRoutes from 'routes/pipeline/routes';
import templateMessageRoutes from 'routes/template-message/routes';
import templateMessageWhatsAppRoutes from 'routes/template-message-whatsapp/routes';
import notificationCenterRoutes from 'routes/notification-center/routes';
import webhooksRoutes from 'routes/webhook/routes';
import sourceRoutes from 'routes/source-generation/routes';
import productRoutes from 'routes/product/routes';
import complianceRoutes from 'routes/compliance/routes';
import privacyRoutes from 'routes/privacy/routes';
import trainingAdminRoutes from 'routes/training-admin/routes';
import trainingRoutes from 'routes/training/routes';
import channelUsesRoutes from 'routes/channel-uses/routes';
import bestMatchRoutes from 'routes/best-match/routes';
import redistributionRoutes from 'routes/redistribution/routes'
import surveyRoutes from 'routes/survey/routes';
import ticketRoutes from 'routes/ticket/routes';
import campaignRoutes from 'routes/campaign/routes';
import platformIndicatorsRoutes from 'routes/platform-indicators/routes';
import teamRoutes from 'routes/teams/routes';
import deleteLeads from 'routes/delete-leads/routes';
import clusterLeads from 'routes/lead-cluster/routes';
import taskRoutes from 'routes/task/routes';
import conversationKeyword from 'routes/conversation-keyword/routes';
import integration from 'routes/integration/routes';
import userRoutes from 'routes/user/routes';
import timeTableRoutes from 'routes/timetable/routes';
import RouteWrapper from './RouteWrapper';

const routes = [
  {
    component: RouteWrapper,
    path: '/',
    routes: [
      ...additionalInformationRoutes,
      ...fieldConfigRoutes,
      ...channelRoutes,
      ...groupRoutes,
      ...hubRoutes,
      ...megadashRoutes,
      ...intelDataRoutes,
      ...leadRoutes,
      ...importRoutes,
      ...storeRoutes,
      ...topicRoutes,
      ...articleRoutes,
      ...settingsRoutes,
      ...pipelineRoutes,
      ...statusRoutes,
      ...userRoutes,
      ...timeTableRoutes,
      ...templateMessageRoutes,
      ...templateMessageWhatsAppRoutes,
      ...notificationCenterRoutes,
      ...webhooksRoutes,
      ...sourceRoutes,
      ...productRoutes,
      ...complianceRoutes,
      ...privacyRoutes,
      ...trainingAdminRoutes,
      ...trainingRoutes,
      ...channelUsesRoutes,
      ...bestMatchRoutes,
      ...redistributionRoutes,
      ...surveyRoutes,
      ...ticketRoutes,
      ...campaignRoutes,
      ...platformIndicatorsRoutes,
      ...teamRoutes,
      ...deleteLeads,
      ...clusterLeads,
      ...conversationKeyword,
      ...taskRoutes,
      ...integration,
      {
        component: LazyComponent(lazy(() => import('./routes/home'))),
        exact: true,
        path: '/',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/home/v2/client'))),
        exact: true,
        path: '/client/:clientId',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/home/v2/client/investments'))),
        exact: true,
        path: '/client/:clientId/investments/:investmentId',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/home'))),
        exact: true,
        path: '/home',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/chat/Chat'))),
        exact: true,
        path: '/chat',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/chat/Chat'))),
        path: '/chat/:id',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/login/Login'))),
        path: '/login',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/password-recover/PasswordRecover'))),
        path: '/password-recover',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/chart/Chart'))),
        path: '/chart/:id',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/health/Health'))),
        path: '/health/',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/embedded/Webphone'))),
        exact: true,
        path: '/webphone/:leadId/',
      },
      {
        component: LazyComponent(lazy(() => import('./routes/not-found/NotFound'))),
      },
    ],
  },
];

export default routes;
