import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Button from "components/buttons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {IconButton, makeStyles, Paper, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modalBorder: {
    borderRadius: "20px",
  },
  modalContent: {
    padding: "0px 6%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing(3),
    left: "auto",
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  actions: {
    background: theme.palette.type === "light" ? "#F3F3F3" : "#2C2E35",
    borderRadius: "0px 0px 20px 20px",
    padding: "30px 10%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

// Paper
function PaperComponentCustom(props) {
  const {className} = props;
  const classes = useStyles();
  return <Paper {...props} className={cn(className, classes.modalBorder)} />;
}

function Modal(props) {
  const {
    actions,
    actionsComponent,
    isOpen = false,
    children,
    disableActions,
    handleClose,
    handleLeftButtonClick,
    handleRightButtonClick,
    handleRightButtonForm,
    title,
    subTitle,
    fullWidth,
    maxWidth,
    fullScreen,
    leftButtonTitle,
    rightActionType,
    rightButtonTitle,
    disableEnforceFocus, // Pass true here to use Tinymce inside this modal
    disableBackdropClick,
  } = props;

  const classes = useStyles();

  const handleCloseModal = () => {
    if (handleClose) handleClose();
  };

  // Handle Actions
  const handleLeftAction = () => {
    if (handleLeftButtonClick) handleLeftButtonClick();
  };

  const handleRightAction = () => {
    if (handleRightButtonClick) handleRightButtonClick();
  };

  return (
    <Dialog
      open={isOpen}
      PaperComponent={PaperComponentCustom}
      onClose={handleCloseModal}
      aria-labelledby="dialog"
      aria-describedby="description"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      disableEnforceFocus={disableEnforceFocus}
      // @see https://stackoverflow.com/a/69179372/1345327
      disableBackdropClick={disableBackdropClick}
    >
      <DialogTitle
        disableTypography={true}
        className={classes.title}
        id="dialog"
      >
        <Typography variant="h5">{title}</Typography>
        {subTitle && (
          <Typography
            variant="subtitle1"
            color="primary"
            className={classes.subtitle}
          >
            {subTitle}
          </Typography>
        )}
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <DialogContentText id="description">
          {children(props)}
        </DialogContentText>
      </DialogContent>
      {actions && (
        <DialogActions className={classes.actions}>
          <Button
            handleClick={handleLeftAction}
            color="primary"
            variant="outlined"
            disabled={disableActions}
          >
            {leftButtonTitle}
          </Button>
          {rightButtonTitle && (
            <Button
              handleClick={handleRightAction}
              color="primary"
              variant="contained"
              type={rightActionType}
              form={handleRightButtonForm}
              disabled={disableActions}
            >
              {rightButtonTitle}
            </Button>
          )}
        </DialogActions>
      )}
      {actionsComponent && actionsComponent()}
    </Dialog>
  );
}

Modal.propTypes = {
  actions: PropTypes.bool,
  actionsComponent: PropTypes.func,
  children: PropTypes.func, // Função pq precisa passar o handleClose
  className: PropTypes.string,
  disableActions: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool, // Pass true here to use Tinymce inside this modal
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleClose: PropTypes.func,
  handleLeftButtonClick: PropTypes.func,
  handleRightButtonClick: PropTypes.func,
  handleRightButtonForm: PropTypes.string,
  isOpen: PropTypes.bool,
  leftButtonTitle: PropTypes.string,
  maxWidth: PropTypes.string, //xs, md, sm, lg, xl
  rightActionType: PropTypes.string,
  rightButtonTitle: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

export default Modal;
