import React, {Fragment, useEffect, useState, useCallback} from 'react';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import {useSocketContext} from 'context';
import {Badge, makeStyles} from '@material-ui/core';
import IconButton from 'components/buttons/IconButton';
import NotificationsOutlined from '@material-ui/icons/NotificationsOutlined';
import useSound from 'use-sound';
import sound from 'assets/sound.mp3';
import Drawer from 'components/drawer/Drawer';
import Notifications from './Notifications';

function NotificationButton() {
  const useStyles = makeStyles((theme) => ({
    icon: {
      fontSize: 28,
      [theme.breakpoints.down("1300")]: {
        fontSize: 22,
      },
      [theme.breakpoints.down("1100")]: {
        fontSize: 20,
      },
    },
  }));

  const classes = useStyles();

  const [drawerNotifications, setDrawerNotifications] = useState(false);

  const {socketCore} = useSocketContext();
  const post = useDispatchPost();
  const [play] = useSound(sound);

  const countNotifications = useAppValue('countNotifications');
  const setCountNotifications = useDispatchSetAppValue('countNotifications');

  const setCountNotificationsNotAccept = useDispatchSetAppValue('countNotificationsNotAccept');

  const getNotification = useCallback(() =>{
    post('/notification-center/count').then((responseData) => {
      setCountNotifications(responseData.data);
      setCountNotificationsNotAccept(responseData.countNotAccepted);
    });
  }, [post, setCountNotifications, setCountNotificationsNotAccept])

  useEffect(() => {
    if (!socketCore) return undefined;

    const handleNotification = (data) => {
      getNotification()
      play();
    };

    socketCore.on('notification', handleNotification);
    return () => {
      socketCore.removeListener('notification');
    };
  }, [getNotification, play, socketCore]);

  useEffect(() => {
    getNotification()
  }, [getNotification]);

  return (
    <Fragment>
      <IconButton alt="Notificações" handleClick={() => setDrawerNotifications(true)} data-tour="tour_home_menu_notificaoes">
        <Badge badgeContent={countNotifications} max={9} overlap="circular" color="error">
          <NotificationsOutlined className={classes.icon} />
        </Badge>
      </IconButton>
      <Drawer title="Notificações" direction={"right"} isOpen={drawerNotifications} onClose={() => setDrawerNotifications(false)}>
        <Notifications />
      </Drawer>
    </Fragment>
  );
}

export default NotificationButton;
