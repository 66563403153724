import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

export default [
  {
    component: LazyComponent(lazy(() => import('./Update'))),
    path: '/templateWhatsApp/update/:id',
  },
  {
    component: LazyComponent(lazy(() => import('./Create'))),
    path: '/templateWhatsApp/create',
  },
];
