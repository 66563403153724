import React from 'react';
import PropTypes from 'prop-types';
import {getError} from '../form/utils.js';
import {FormControl, FormHelperText, IconButton, InputAdornment, InputLabel} from '@material-ui/core';
import MaterialInput from '@material-ui/core/Input'

const Input = React.forwardRef((props, ref) => {
  const {
    adornmentIcon,
    adornmentButtonType,
    autoComplete,
    className,
    disabled,
    errors,
    helperText,
    label,
    name,
    onChange,
    handleAdornmentClick,
    type,
    defaultValue,
    value,
    margin,
    placeholder,
    onBlur,
  } = props;

  const handleChange = (e) => {
    if (onChange) onChange(e);
  };

  const handleOnBlur = (e) => {
    if (onBlur) onBlur();
  }

  const handleAdornment = () => {
    if (handleAdornmentClick) handleAdornmentClick()
  };

  const id = name; // Will always be the same as name
  const error = getError(errors, name);
  return (
    <FormControl fullWidth={true} margin={margin}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <MaterialInput
        autoComplete={autoComplete}
        className={className}
        error={error ? true : false}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleOnBlur}
        inputRef={ref}
        type={type}
        defaultValue={defaultValue}
        value={value}
        endAdornment={
          adornmentIcon ?
            <InputAdornment position="end">
              <IconButton onClick={handleAdornment} type={adornmentButtonType}>
                {adornmentIcon}
              </IconButton>
            </InputAdornment>
          :
          <InputAdornment position="end"><span /></InputAdornment>
        }
      />
      <FormHelperText>{error ? helperText : ''}</FormHelperText>
    </FormControl>
  );
});

Input.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

Input.defaultProps = {
  disabled: false,
  type: 'text',
};

export default Input;
