import React, {useState} from 'react';
import {useAppValue} from 'redux/hooks/app';
import {Tabs, Tab, useTheme} from '@material-ui/core';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import HistoryIcon from '@material-ui/icons/History';
import SwipeableViews from 'react-swipeable-views';
import TabContent from 'components/tabs/TabContent';

import Dialer from 'components/voip/Dialer';
import DialerCallHistoric from 'components/voip/DialerCallHistoric';
 

function VoipDialer() {
  const theme = useTheme();

  const voipDialerState = useAppValue('VOIP_DIALER_callStatus');

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  }

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  return (
    <div>
      {/** Tabs */}
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        textColor="primary"
        variant="fullWidth"
        centered={true}
        aria-label="fullWidth-tabs"
      >
        <Tab icon={<DialerSipIcon />} iconPosition="start" label="Discadora" {...a11yProps(0)} />
        <Tab icon={<HistoryIcon />} iconPosition="start" label="Histórico" disabled={voipDialerState === 'dialing' || voipDialerState === 'ringing'} {...a11yProps(1)} />
      </Tabs>
      {/** Tabs content */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={currentTab}
        tabIndex={currentTab}
        style={{outline: 'none'}}
      >
        <TabContent value={currentTab} index={0} dir={theme.direction}>
          <Dialer />
        </TabContent>
        <TabContent value={currentTab} index={1} dir={theme.direction}>
          <DialerCallHistoric />
        </TabContent>
      </SwipeableViews>
    </div>
  );
}

export default VoipDialer;
