import React, {useReducer} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatchPost} from 'redux/hooks/fetch';
import {useDidMountEffect} from 'utils/hooks';
import {UserContext} from './index';

const initialState = {
  // TODO: completar
  groupType: null,
  groupId: null,
  groupSalesId: null,
  groupName: null,
  isManager: false,
  isAuthenticated: false,
  isAuthenticationChecked: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'checkAuthentication':
      return {
        ...state,
        isAuthenticationChecked: true,
      };
    case 'resetAuthentication':
      return {
        ...initialState,
        isAuthenticationChecked: true,
      };
    case 'setAuthentication':
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticationChecked: true,
      };
    case 'setUserData':
      return {...state, isAdmin: action.payload.isAdmin || false ,...action.payload};
    default:
      throw new Error();
  }
}

function UserContextProvider(props) {
  const {children} = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const history = useHistory();
  const {pathname} = useLocation();

  const post = useDispatchPost();

  useDidMountEffect(() => {
    let jwt = localStorage.getItem('jwt');
    
    if (!jwt && pathname.includes('webphone')) {
      const jwtParam = pathname.split('/')[pathname.split('/').length - 1];
      if (jwtParam) {
        jwt = jwtParam;
        localStorage.setItem('jwt', jwtParam);
      }
    }

    if (!jwt) {
      if (pathname.indexOf('login') === -1 && pathname.indexOf('password-recover') === -1 && pathname.indexOf('health') === -1 && pathname.indexOf('privacy') === -1) {
        history.push('/login');
      }
      dispatch({type: 'checkAuthentication'});
      return;
    }

    post('/user/my-info').then((responseData) => {
      const {data} = responseData;
      if (data.forceLogoff) {
        localStorage.removeItem('jwt');
        dispatch({type: 'resetAuthentication'});
        history.push('/login');
        function gtag(){window.dataLayer.push(arguments);}
        gtag('event', 'force_logoff');
        return;
      }
      if (pathname.indexOf('login') !== -1) history.push('/chat');
      dispatch({type: 'setAuthentication'});
      dispatch({type: 'setUserData', payload: data});
      function gtag(){window.dataLayer.push(arguments);}
      gtag('set', 'user_properties', {
        id: data._id,
        groupType: data.groupType,
      });
      const dataGA = {};
      Object.keys(data).forEach((title) => {dataGA[`user_data_${title}`] = data[title]});
      gtag('event', 'set_token', dataGA);
    }).catch(() => {
      if (pathname.indexOf('login') === -1) {
        history.push('/login');
      }
      localStorage.removeItem('jwt');
      dispatch({type: 'resetAuthentication'});
      function gtag(){window.dataLayer.push(arguments);}
      gtag('event', 'force_logoff');
    });
  });

  // set /user/my-info return
  const setUserData = (data) => {
    dispatch({type: 'setUserData', payload: data});
  }

  const resetToken = () => {
    localStorage.removeItem('jwt');
    dispatch({type: 'resetAuthentication'});
    function gtag(){window.dataLayer.push(arguments);}
    gtag('event', 'reset_token');
  };

  const setToken = (token) => {
    localStorage.setItem('jwt', token);
    post('/user/my-info').then((responseData) => {
      const {data} = responseData;
      dispatch({type: 'setAuthentication'});
      dispatch({type: 'setUserData', payload: data});
      function gtag(){window.dataLayer.push(arguments);}
      gtag('set', 'user_properties', {
        id: data._id,
        groupType: data.groupType,
      });
      const dataGA = {};
      Object.keys(data).forEach((title) => {dataGA[`user_data_${title}`] = data[title]});
      gtag('event', 'set_token', dataGA);
    }).catch(() => {
      localStorage.removeItem('jwt');
    });
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        resetToken,
        setToken,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.node,
};

export default UserContextProvider;
