import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

export default [
  {
    component: LazyComponent(lazy(() => import('./List'))),
    path: '/user/list',
  },
  {
    component: LazyComponent(lazy(() => import('./Create'))),
    path: '/user/create',
  },
  {
    component: LazyComponent(lazy(() => import('./Update'))),
    path: '/user/update/:id',
  },
  {
    component: LazyComponent(lazy(() => import('./MyInfo'))),
    path: '/user/my-info',
  },
  {
    component: LazyComponent(lazy(() => import('./UserSignature'))),
    path: '/user/set-signature',
  },
];
