import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import {Divider, ListItemIcon, Typography} from '@material-ui/core';

function Dropdown(props) {
  const {
    className,
    hasArrow,
    label,
    itensList,
    selected,
    labelEmpty,
    dataTour,
    openNewTab,
  } = props;

  const useStyles = makeStyles((theme) => ({
    popper: {
      zIndex: theme.zIndexes.popper,
    },
  }));

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseLink = (item) => {
    if (item.link) {
      if(openNewTab) {
        window.open(item.link, "_blank");
      } else {
        history.push(item.link);
      }
    }
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // Return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Fragment>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={className}
        disableRipple={true}
        endIcon={hasArrow ? <ExpandMoreTwoToneIcon /> : null}
        data-tour={dataTour}
      >
        {label}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} className={classes.popper} role={undefined} transition={true} style={{maxHeight: '30vh', overflow: 'auto'}}>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {itensList.map((item, index) => {
                    if (item.disabled) return null;
                    return (
                      <div>
                        <MenuItem onClick={(event) => {handleClose(event); handleCloseLink(item)}} selected={selected}>
                          {item.icon &&
                            <ListItemIcon>
                              {item.icon}
                            </ListItemIcon>
                          }
                          <Typography variant="inherit">{item.label}</Typography>
                        </MenuItem>
                        {/* remove divider on last item */}
                        {index < itensList.length - 1 && (
                          <Divider variant="middle" />
                        )}
                      </div>
                    )
                  })}
                  {itensList.length === 0 && labelEmpty && (
                    <div>
                      <MenuItem>
                        <Typography variant="inherit">{labelEmpty}</Typography>
                      </MenuItem>
                    </div>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  dataTour: PropTypes.string,
  hasArrow: PropTypes.bool,
  itensList: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.node.isRequired,
  labelEmpty: PropTypes.string,
  selected: PropTypes.string,
};

export default Dropdown;
