import {useDispatchPost} from 'redux/hooks/fetch';
import {useCallback} from 'react';

const limit = 4;

function useFetchNotificationsList() {
  const post = useDispatchPost();

  const fetchNotificationList = useCallback((params) => {
    const {
      offset,
    } = params;
    const filters = {
      limit,
      offset: typeof offset !== 'undefined' ? offset : 0,
    };
    return post('/notification-center/my-notifications', filters);
  }, [post]);

  return fetchNotificationList;
}

export default useFetchNotificationsList;
