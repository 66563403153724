import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

export default [
  {
    component: LazyComponent(lazy(() => import('./lead/auth'))),
    exact: true,
    path: '/privacy/lead/:id',
  },
  {
    component: LazyComponent(lazy(() => import('./lead/fields'))),
    exact: true,
    path: '/privacy/lead/:id/fields',
  },
];
