import React, {Fragment, useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import UserContextProvider from 'context/UserContextProvider';
import {renderRoutes} from 'react-router-config';
import routes from './routes';
import store from './redux/store';
import SocketContextProvider from './context/SocketContextProvider';
import * as NT from '@nimbus-target/ui';

function App() {
  const [customRoutesLoaded, setCustomRoutesLoaded] = useState(false);
  const [customRoutes, setCustomRoutes] = useState([]);

  useEffect(() => {
    const loadCustomRoutes = async () => {
      const {default: setup} = await import('mock-data/setup');
      if (!setup.amil.hasCustomRoutes) return;
      // TODO: Esse import ta gerando um warning em dev, dizendo que a pasta nao existe.
      // Precisa abrir uma issue no CRA sobre isso, falando que nao deveria tentar carregar
      // Quando nao chega no import
      try {
        const tenant = 'amil'; // FIXME: amil hardcoded
        const {default: tenantRoutes} = await import(`tenants/${tenant}/routes.js`);
        setCustomRoutes(tenantRoutes);
      } catch (_err) {
        /* eslint-disable-next-line no-console */
        console.warn('It seems that you forgot to create the routes files in the tenant folder');
      }
    };
    loadCustomRoutes().then(() => {
      setCustomRoutesLoaded(true);
    });
  }, []);

  // console.info('theme:', NT.themes.light);
  return (
    <Fragment>
      {customRoutesLoaded ? (
        <Provider store={store}>
          <LoadingIndicator />
          <Router>
            <NT.Main injectFirst={true} injectCssBaseline={true}>
              <UserContextProvider>
                <SocketContextProvider>
                  {renderRoutes([...customRoutes, ...routes])}
                </SocketContextProvider>
              </UserContextProvider>
            </NT.Main>
          </Router>
        </Provider>
      ) : null}
    </Fragment>
  );
}

export default App;
