import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Modal from 'components/modal/Modal';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/buttons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useUserContext} from 'context/index';
import {useDispatchSetAppSuccessMessage} from 'redux/hooks/app';

function AcceptModal() {
  const userTerms = useAppValue('userTerms');
  const setUserTerms = useDispatchSetAppValue('userTerms');

  // Styles
  const useStyles = makeStyles((theme) => ({
    actions: {
      background: theme.palette.type === 'light' ? '#F3F3F3' : '#2C2E35',
      borderRadius: '0px 0px 20px 20px',
      padding: '30px 10%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    terms: {
      color: 'black',
    },
  }));

  const classes = useStyles();
  const post = useDispatchPost();
  let [terms, setTerms] = useState([]);
  const [checked, setChecked] = useState(false);
  const user = useUserContext();
  const setAppSuccess = useDispatchSetAppSuccessMessage();

  const getTerms = () => {
    post('/user-terms/active').then((responseData) => {
      setTerms(responseData.data);
    });
  }

  useEffect(() => {
    getTerms();

    post('/user-terms/wassignature').then((response) => {
      if(response.countSigned === 0 && response.countPublic > 0){
        setUserTerms(true);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTerms();
    setChecked(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTerms]);

  const assign = () => {
    post('/user-terms/signature').then((responseData) => {
      setUserTerms(false);
      setAppSuccess("Termos assinado com sucesso");
      getTerms();
    });
  }

  const closeModal = () => {
    if(terms === null) return false;
    if (terms.signature.find(e => e._id === user._id)) {
      setUserTerms(false)
      return false;
    }
  }

  const acceptButton = () => {
    if (terms=== null) return '';
    if (terms.signature) {
      if (terms.signature.find(e => e._id === user._id)) {
        return '';
      }
    }

    return (
      <DialogActions className={classes.actions}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              color="primary"
            />
          }
          label="Li e concordo com os termos de uso da plataforma"
        />
        <Button
          handleClick={assign}
          color="primary"
          variant="contained"
          disabled={!checked}
        >
          Avançar
          </Button>
      </DialogActions>
    )
  }

  return (
    <div className={classes.container}>
      <Modal
        isOpen={userTerms}
        handleClose={closeModal}
        fullWidth={true}
        maxWidth="md"
        title="Termos de uso"
        actionsComponent={acceptButton}
      >
        {({handleClose}) => {
          return (
            <p
              className={classes.terms}
              dangerouslySetInnerHTML={{__html: terms && terms.text}}
            />
          )
        }}
      </Modal>
    </div>
  );
}

export default AcceptModal;
