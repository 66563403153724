import React, {useCallback, useEffect, useState} from 'react';
import {useDispatchPost} from 'redux/hooks/fetch';
import {Collapse} from '@material-ui/core';
import {formatDateHour} from 'utils/moment';
import {useUserContext} from 'context';
import Button from 'components/buttons/Button';
import Input from 'components/inputs/Input';
import {useForm} from 'react-hook-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from 'components/accordion/Accordion';
import {useDispatchSetAppError, useDispatchSetAppSuccessMessage} from 'redux/hooks/app';
import {useHistory} from 'react-router-dom';


export default function FavoriteLeads() {

  const post = useDispatchPost();
  const setAppSuccess = useDispatchSetAppSuccessMessage();
  const setAppError = useDispatchSetAppError();

  const {control, register, watch} = useForm();

  const {_id} = useUserContext();

  const [userFavoriteLeads, setUserFavoriteLeads] = useState([]);

  const [createNew, setCreateNew] = useState(false);
  const [newName, setNewName] = useState('');

  const history = useHistory();

  const getLists = useCallback(() => {
    post('/lead-favorite-list/list', {userId: _id}).then((responseData) => {
      console.log('responseData.data: ', responseData.data);
      setUserFavoriteLeads(responseData.data);
    })
  }, [_id, post]);
  
  useEffect(() => {
    getLists();
  }, [getLists]);

  const goToLead = (leadId) => {
    if (!leadId) return;
    history.push(`/chat/${leadId}`);
  }

  const handleCreateNew = () => {
    post('/lead-favorite-list/create', {name: newName, userId: _id.toString()})
    .then(() => {
      getLists();
      setNewName('');
      setAppSuccess('Nova lista criada com sucesso!');
    })
    .catch(() => {
      setAppError('Falha ao criar nova lista.');
    });
  }

  const handleDeleteList = (listId) => {
    post('/lead-favorite-list/remove', {id: listId})
    .then(() => {
      getLists();
      setAppSuccess('Lista removida com sucesso!');
    })
    .catch(() => {
      setAppError('Falha ao remover lista.');
    });
  }

  const handleRemoveLeadFromList = (favList, leadId) => {
    post('/lead-favorite-list/remove-lead', {listId: favList._id, leadId: leadId})
      .then(() => {
        setAppSuccess('Lista atualizada com sucesso!');
        getLists();
      })
      .catch(() => {
        setAppError('Falha ao atualizar lista.');
      })
  }

  const handleUpdateListName = (favoriteLeadList) => {
    const data = watch(favoriteLeadList._id);
    // console.log('watch: ', data)
    post('/lead-favorite-list/update', {id: favoriteLeadList._id, name: data})
    .then(() => {
      setAppSuccess('Nome da lista atualizado com sucesso!');
      getLists();
    })
    .catch(() => {
      setAppError('Falha ao alterar nome da lista.');
    })
  }

  return (
    <div>
      <div>
        <Button
          // className={classes.createNewButton}
          handleClick={() => {setCreateNew(!createNew)}}
        >
          {createNew ? 'Cancelar' : 'Criar nova lista'}
        </Button>
        <Collapse orientation="horizontal" in={createNew}>
          <div style={{display: 'flex'}}>
            <Input
              name="listName"
              label="Digite o nome da lista"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <Button handleClick={handleCreateNew}>
              Confirmar
            </Button>
          </div>
        </Collapse>
      </div>
      {userFavoriteLeads && userFavoriteLeads.map((favoriteLeadList) => {
        return (
          <div style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid rgb(200,200,200) 1px'}}>
            <form>
              <Accordion
                expandIcon={<ExpandMoreIcon />}
                title={
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12pt'}}>{favoriteLeadList.name}</span>
                    <span style={{fontSize: '10pt'}}>{`Contém ${favoriteLeadList.leadsInfo.length} leads`}</span>
                    <span style={{fontSize: '8.5pt'}}>{`Criado ${formatDateHour(favoriteLeadList.createdAt)}`}</span>
                  </div>
                }
                defaultExpanded={false}
                titleColor='black'
                style={{padding: 0}}
              >
                <div>
                  <div>
                    <Input
                      name={favoriteLeadList._id}
                      control={control}
                      defaultValue={favoriteLeadList.name}
                      ref={register({required: true})}
                    />
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20}}>
                      <Button handleClick={() => handleUpdateListName(favoriteLeadList)}>
                        Atualizar nome
                      </Button>
                      <Button handleClick={() => handleDeleteList(favoriteLeadList._id)}>
                        Apagar lista
                      </Button>
                    </div>
                  </div>
                  {favoriteLeadList.leadsInfo.map((leadInfo) => {
                    return (
                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 10, padding: 15, border: 'solid rgb(200,200,200) 1px'}}>
                        <div style={{paddingBottom: '15px', marginTop: '-5px'}}>{leadInfo.leadName}</div>
                        <div style={{marginBottom: '-10px'}}>
                          <Button 
                            style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: 12, fontSize: '9pt', padding: '10px'}} 
                            handleClick={() => goToLead(leadInfo.leadId)}
                          >
                            Ver Lead
                          </Button>
                          <Button 
                            style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: 12, fontSize: '9pt', padding: '10x', marginLeft: '20px'}} 
                            handleClick={() => handleRemoveLeadFromList(favoriteLeadList, leadInfo.leadId)}
                          >
                            Remover da lista
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Accordion>
            </form>
          </div>
          )},
        )}
    </div>
  );
}