import IconButton from "components/buttons/IconButton";
import React, {Fragment, useState, useCallback} from "react";
import styles from "./NotificationAttachmentsModal.module.css";
import {Typography, Card, CardContent} from "@material-ui/core";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import ImageIcon from "@material-ui/icons/Image";
import AttachmentIcon from "@material-ui/icons/Attachment";
import MessageIcon from "@material-ui/icons/Message";
import GetApp from '@material-ui/icons/GetApp';

const NotificationAttachmentsModal = (props) => {
  const {attachments, title, description} = props.notification;

  const [selectedAtt, setSelectedAtt] = useState(null);

  

  const renderContent = useCallback(
    (att) => {
      if (!att) {
        return (
          <Typography style={{whiteSpace: "pre-wrap"}}>
            {description}
          </Typography>
        );
      }
      if (att.type.toString().includes("image")) {
        return (
          <div className={styles.media}>
            <img alt={title} src={att.address} />
          </div>
        );
      } else if (att.type.toString().includes("video")) {
        return (
          <div className={styles.media}>
            <video controls={true}>
              <source src={att.address} type="video/mp4" />
            </video>
          </div>
        );
      } else {
        return (
          <div>
            <div className={styles.downloadTextDiv}>
              <h3 style={{paddingBottom: '1vh'}}>Não foi possível visualizar o arquivo</h3>
                <a
                  className={styles.download}
                  href={att.address}
                  rel="noopener noreferrer"
                  target="_blank"
                  download={att.address}>
                    <h4 style={{paddingBottom: '1vh'} } >Clique para baixar</h4>
                    <GetApp style={{width: 30, height: 30}}/>
                </a>
            </div>
          </div>
        );
      }
    },
    [description, title],
  );

  return (
    <Fragment>
      <div className={styles.containerDiv}>
        <div className={styles.buttonsRow}>
          <IconButton
            handleClick={() => {
              setSelectedAtt(null);
            }}
            className={styles.iconButton}
          >
            <MessageIcon style={{width: 25, height: 25}} />
            <p className={styles.iconText} style={!selectedAtt ? {fontWeight: 'bold'} : {}} > Corpo da notificação</p>
          </IconButton>
          {attachments.map((att, i) => {
            return (
                <IconButton
                  handleClick={() => {
                    setSelectedAtt(att);
                  }}
                  className={styles.iconButton}
                >
                  {att.type.toString().includes("image") && (
                    <ImageIcon style={{width: 25, height: 25}} />
                  )}
                  {att.type.toString().includes("video") && (
                    <SlideshowIcon style={{width: 25, height: 25}} />
                  )}
                  {!att.type.toString().includes("image") &&
                    !att.type.toString().includes("video") && (
                      <AttachmentIcon style={{width: 25, height: 25}} />
                    )}
                  <p className={styles.iconText} style={selectedAtt===att ? {fontWeight: 'bold'} : {fontWeight: 'normal'} }> {att.title} </p>
                </IconButton>
            );
          })}
        </div>

        <div className={styles.content}>
          <Card variant={"elevation"} className={styles.card}>
            {/*<CardHeader title={selectedAtt ? selectedAtt.title : title} /> */}
            <CardContent className={styles.cardContent}>
              <h2>{selectedAtt ? selectedAtt.title : title}</h2>
              {renderContent(selectedAtt)}
            </CardContent>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default NotificationAttachmentsModal;
