import {useUserContext} from 'context/index';
import permissions from './permissions';

function useIsAllowed(/** @type {keyof typeof permissions} */ permissionKey) {
  const userContext = useUserContext();

  const currentPermission = permissions[permissionKey];
  if (!currentPermission) return false;

  const {groupTypes, roleAdministrator, roles, manager} = permissions[permissionKey];

  // OR condition, one groupType is required for permission
  const groupTypeMatch = groupTypes.includes(userContext.groupType);

  // OR condition, one manager type ('store' or 'team') is required for permission
  // if there is a limitation by manager, 
  const managerMatch = userContext.groupType !== 'store' || (manager && manager.includes(userContext.manager));

  if(permissionKey === 'leadImport') {
    if (userContext['canImportLeadsExcel']) return true;
  }
  

  // If role administrator doesn`t superimpose roles match
  if (!roleAdministrator) {
    // Verify roles and return true or false
    const rolesMatch = roles.reduce((memo, value) => {
      return userContext[value] ? memo : false;
    }, true);
    // All conditions are true?
    return managerMatch && groupTypeMatch && rolesMatch;
  } else {
    // All conditions are true? (Admin superimpose roles match)
    return managerMatch && groupTypeMatch && userContext.isAdmin;
  }
}

export default useIsAllowed;
