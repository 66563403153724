import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Button from 'components/buttons/Button';
import Input from 'components/inputs/Input';
import Modal from 'components/modal/Modal';
import {useUserContext} from 'context/index';
import {useAppValue, useDispatchSetAppError, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import { makeStyles } from '@material-ui/core';

// FIXME: essa logica do useEffect e do if return deveria estar no fetch
// antes de setar o isLoginModalOpen, mas lá não temos acesso ao contexto.
// Precisa tira o fetch do actionCreators e fazer tudo direto no hook, assim teria
// acesso ao contexto e fica tudo mais simples e menos passivel de bug
function LoginModal() {
  const {email, isAuthenticated, setToken} = useUserContext();

  const {
    formState,
    register,
    handleSubmit: handleSubmitLib, // FIXME: nome melhor
    errors,
  } = useForm();

  // Styles
  const useStyles = makeStyles((theme) => ({
    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      marginTop: '20px',
    },
  }));

  const classes = useStyles();

  // Read the formState before render to subscribe the form state through Proxy. See the docs.
  const {isSubmitting} = formState;

  const isLoginModalOpen = useAppValue('isLoginModalOpen');

  const post = useDispatchPost();
  const setAppError = useDispatchSetAppError();
  const setIsLoginModalOpen = useDispatchSetAppValue('isLoginModalOpen');

  useEffect(() => {
    if (!isAuthenticated && isLoginModalOpen) setIsLoginModalOpen(false);
  }, [isAuthenticated, isLoginModalOpen, setIsLoginModalOpen]);

  const handleClose = () => {
    setIsLoginModalOpen(false);
  };

  const handleSubmit = async (data) => {
    try {
      const responseData = await post('/user/login', {...data, email});
      setToken(responseData.data);
      setIsLoginModalOpen(false);
    } catch (e) {
      setAppError(e.message);
    }
  };

  if (!isAuthenticated || !isLoginModalOpen) return null;
  return (
    <Modal isOpen={true} fullWidth={true} maxWidth={'md'} handleClose={handleClose} title="Sua sessão expirou">
      {() => {
        return (
          <form onSubmit={handleSubmitLib(handleSubmit)}>
            <Input
              ref={register({required: true})}
              type="password"
              name="password"
              autoComplete="new-password"
              label="Senha"
              errors={errors}
            />
            <div className={classes.button}>
              <Button
                disabled={isSubmitting}
                color="primary"
                type="submit"
              >
                Confirmar
              </Button>
            </div>
          </form>
        );
      }}
    </Modal>
  );
}

export default LoginModal;
