import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Dropdown from 'components/dropdown/Dropdown';
import {useUserContext} from 'context/index';
import {useDispatchPost} from 'redux/hooks/fetch';

function ChartsMenuDropdown() { //<<
  const useStyles = makeStyles((theme) => ({
    textButton: {
      fontFamily: 'Montserrat',
      textTransform: 'capitalize',
      fontSize: 14,
      [theme.breakpoints.down("1300")]: {
        fontSize: 12,
      },
    },
  }));

  const classes = useStyles();
  const [charts, setCharts] = useState([]);

  const {
    clientId, _id: userId, isAdmin: userIsAdmin, groupType: userGroupType, groupId: userGroupId, canAccessReports,
  } = useUserContext();

  const post = useDispatchPost();

  useEffect(() => {
    if (!clientId) return;
    post('/report/list', {clientId})
      .then(async (responseData) => {
        // Request group chain list to get associated stores
        let associatePDVs = await post('/group/chain-list', {id: userGroupId, type: 'child'});
        // Filter (TODO: This is too wrong bro, we need to improve and maybe take this rule to the backend)
        const chartRes = await responseData.data.filter((e) => {

          const chart = e;
          chart.link = `/chart/${chart._id}`;
          chart.label = chart.name;

          let groupTypeName;
          switch (chart.groupType) {
            case 'hub':
              groupTypeName = chart.groupType;
              chart.sort = 1;
              break;
            case 'group':
              groupTypeName = 'association';
              chart.sort = 2;
              break;
            case 'pdv':
              groupTypeName = 'store';
              chart.sort = 3;
              break;
            default:
              groupTypeName = chart.groupType;
              chart.sort = 4;
              break;
          }

          // nimbus view all
          if (userIsAdmin && userGroupType === 'nimbus') {
            return e;
          }
          // same groupType and groupId
          if (
            canAccessReports === true
            && userGroupType === groupTypeName
            && (chart.groupId === userGroupId)
          ) {
            chart.bold = true;
            return e;
          }
          // hub hierarchy
          if (
            canAccessReports === true
            && userGroupType === 'hub'
            && (groupTypeName === 'association' || groupTypeName === 'store' || groupTypeName === '' || groupTypeName === null)
          ) {
            return e;
          }
          // association hierarchy, linked pdvs
          if (
            canAccessReports === true
            && userGroupType === 'association'
            && groupTypeName === 'store'
            && associatePDVs.data
            && associatePDVs.data.some((pdv) => pdv._id === chart.groupId)
          ) {
            return e;
          }
          // basic user
          if ((chart.groupType === '' || chart.groupType === null) && (chart.groupId === userId)) {
            return e;
          }
          return null;
        });

        const sortedCharts = await chartRes.sort((chart, nextChart) => chart.sort - nextChart.sort);

        setCharts(sortedCharts);
      })
      .catch((err) => {
        console.log(err);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <Dropdown
      hasArrow={true}
      label="Dashboards"
      labelEmpty={'Nenhum relatório disponível'}
      itensList={charts}
      className={classes.textButton}
      dataTour="tour_home_menu_dashboard"
      openNewTab={true}
    />
  );
}

export default ChartsMenuDropdown;
