import React, {Fragment, useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Drawer as MUIDrawer} from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import IconButton from 'components/buttons/IconButton';

function Drawer(props) {
  const {
    children,
    direction,
    disableCloseButton,
    isOpen,
    onClose,
    title,
    enableBackdropInteraction,
    disableBackdropClick,
  } = props;

  const useStyles = makeStyles((theme) => ({
    paper: {
      overflow: 'hidden',
      [theme.breakpoints.up('500')]: {
        width: 400,
      },
      [theme.breakpoints.down('500')]: {
        width: '100%',
      },
    },
    header: {
      display: 'flex',
      height: 50,
      alignItems: 'center',
      margin: '0 5px',
      flexDirection: (!direction || direction === 'left') ? 'row-reverse' : 'row',
      '& > span': {
        width: '100%',
        textAlign: 'center',
      },
    },
    body: {
      height: 'inherit',
      width: '100%',
      overflow: 'auto',
      padding: 10,
      boxSizing: 'border-box',
      background: theme.palette.type === 'dark' ? '#333' : theme.palette.supportOne,
    },
  }));

  const classes = useStyles();

  const handleClose = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      window.history.pushState(
        null,
        document.title,
        window.location.href,
      );
    }
  }, [isOpen]);

  // backbutton and gestures close Drawer
  useEffect(() => {
    window.addEventListener('popstate', handleClose);
    return () => window.addEventListener('popstate', handleClose);
  }, [handleClose]);

  return (
    <Fragment>
      <MUIDrawer
        classes={{paper: classes.paper}}
        anchor={direction}
        open={isOpen}
        onClose={handleClose}
        hideBackdrop={enableBackdropInteraction ? true : false} // Disable the backdrop color/image
        disableEnforceFocus={enableBackdropInteraction ? true : false} // Let the user focus on elements outside the dialog
        style={enableBackdropInteraction ? {position: 'initial'} : {}} // This is the key, reset the position of the dialog, so the user can interact with other elements
        // @see https://stackoverflow.com/a/69179372/1345327
        disableBackdropClick={disableBackdropClick || enableBackdropInteraction ? true : false} // Remove the backdrop click (just to be sure)
      >
        <div className={classes.header}>
          <IconButton handleClick={handleClose} disabled={disableCloseButton}>
            <CancelOutlinedIcon />
          </IconButton>
          <span>{title}</span>
        </div>
        <div className={classes.body}>
          {children}
        </div>
      </MUIDrawer>
    </Fragment>
  );
};

Drawer.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  disableCloseButton: PropTypes.bool,
  enableBackdropInteraction: PropTypes.bool, // Set true if you want to disable backdrop and enable interactions
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default Drawer;
