import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

export default [
  {
    component: LazyComponent(lazy(() => import('./List'))),
    path: '/store/list',
  },
  {
    component: LazyComponent(lazy(() => import('./Create'))),
    path: '/store/create',
  },
  {
    component: LazyComponent(lazy(() => import('./template-message-whatsapp-pdv/Update'))),
    path: '/store/update/:groupId/templateWhatsApp/update/:id',
  },
  {
    component: LazyComponent(lazy(() => import('./template-message-whatsapp-pdv/Create'))),
    path: '/store/update/:groupId/templateWhatsApp/create',
  },
  {
    component: LazyComponent(lazy(() => import('./Update'))),
    path: '/store/update/:id',
  },
];
