import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import {useDispatchPost} from 'redux/hooks/fetch';
import {useDispatchSetAppValue} from 'redux/hooks/app';
import {formatDateHour} from 'utils/moment';
import IconButton from 'components/buttons/IconButton';
import Button from 'components/buttons/Button';
import PersonIcon from '@material-ui/icons/Person';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import AttachmentIcon from "@material-ui/icons/Attachment";
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import useFetchNotificationList from './useFetchNotificationList';
import {Typography, Card, CardHeader, CardContent, CardActions, Avatar, makeStyles} from '@material-ui/core';
import Modal from "components/modal/Modal";
import NotificationAttachmentsModal from './NotificationAttachmentsModal';
import {Fragment} from 'react';

const limit = 10;

function Notifications() {
  const useStyles = makeStyles((theme) => ({
    card: {
      marginBottom: theme.spacing(2),
      borderColor: theme.palette.primary.light,
    },
    linkBtn: {
      marginRight: theme.spacing(1.5),
    },
  }));

  const classes = useStyles();

  const post = useDispatchPost();
  const [notifications, setNotifications] = useState([]);
  const listRef = useRef();
  const [offset, setOffset] = useState(0);

  const setCountNotifications = useDispatchSetAppValue('countNotifications');

  const fetchNotificationList = useFetchNotificationList();

  const setUserTerms = useDispatchSetAppValue('userTerms');

  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);

  //ID da notificação selecionada, usado para o modal de anexos
  const [openNotificationId, setOpenNotificationId] = useState(0);


  const handleLoadMoreData = useCallback((newOffset) => {
    fetchNotificationList({offset: newOffset}).then((responseData) => {
      setNotifications((data) => {
        return [...data, ...responseData.data];
      });
    });
  }, [setNotifications, fetchNotificationList]);

  const handleMarkAsRead = (id) => {
    post('/notification-center/set-read', {id}).then((responseData) => {
      setCountNotifications(responseData.data);
      const update = notifications;
      const notifyIndex = update.findIndex((notify => notify._id === id));
      if (notifyIndex < 0) return;
      // workaround - não estava re-renderizando sem limpar antes
      setNotifications([]);
      update[notifyIndex].recipients.status = 'read';
      setNotifications(update);
      function gtag(){window.dataLayer.push(arguments);}
      gtag('event', 'notification_read', {
        notification_read_id: id,
      });
    });
  };

  const handleScroll = useCallback((e) => {
    const {
      offsetHeight: height,
      scrollHeight,
      scrollTop,
    } = e.target;

    if ((scrollTop + height) >= scrollHeight) {
      const newOffset = offset + limit;
      handleLoadMoreData(newOffset);
      setOffset(newOffset);
    }
  }, [handleLoadMoreData, offset]);

  useEffect(() => {
    const listDomNode = listRef.current;
    listDomNode.addEventListener('scroll', handleScroll);
    return () => {
      listDomNode.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    post('/notification-center/my-notifications', {offset: 0, limit}).then((responseData) => {
      setNotifications(responseData.data);
    });
  }, [post]);

  const renderNotifications = () => {
    if (notifications.length === 0) {
      return (
        <Card>
          <CardHeader subheader={'Sem novas notificações'} />
        </Card>
      );
    }
    return notifications.map((data, i) => {
      return (
        <Fragment>
        <Card
          key={data._id}
          variant={data.recipients.status === 'unread' ? 'outlined' : 'elevation'}
          className={classes.card}
        >
          <CardHeader
            title={data.title}
            subheader={formatDateHour(data.createdAt)}
            avatar={
              <Avatar>
                <PersonIcon />
              </Avatar>
            }
            action={
              data.recipients.status === 'unread' && (
                <IconButton alt="Marcar como lida" handleClick={() => handleMarkAsRead(data._id)}>
                  <DoneAllOutlinedIcon />
                </IconButton>
              )
            }
          />
          <CardContent>
            <Typography style={{whiteSpace: "pre-wrap"}}>
              {data.description}
            </Typography> 
          </CardContent>
          {(data.linkType === 'external' || data.linkType === 'filters' || data.linkType === 'leadId') && (
            <CardActions>
              <Button handleClick={() => {
                window.location.href = data.link;
                function gtag(){window.dataLayer.push(arguments);}
                gtag('event', 'notification_link', {
                  notification_link_id: data._id,
                  notification_link_type: data.linkType,
                });
              }}>
                <LinkOutlinedIcon className={classes.linkBtn} />
                {data.linkType === 'leadId' ? 'Clique para acessar o Lead' : 'Acessar'}
              </Button>
            </CardActions>
          )}
          {(data.linkType === 'termos') && (
            <CardActions>
              <Button handleClick={() => {
                setUserTerms(true)
              }}>
                <LinkOutlinedIcon className={classes.linkBtn} />
                Acessar
              </Button>
            </CardActions>
          )}
          {(data.linkType === 'survey') && (
            <CardActions>
              <Button handleClick={() => {
                window.location.href = "/survey/response/" + data._id;
              }}>
                <LinkOutlinedIcon className={classes.linkBtn} />
                Responder pesquisa
              </Button>
            </CardActions>
          )}
          { data.attachments && data.attachments.length > 0 && (
            <IconButton
            alt="Acessar anexos"
            handleClick={() => {setOpenNotificationId(i); setIsAttachmentModalOpen(true)} }
          >
            <AttachmentIcon/>
            <h5 style={{marginTop: 0, marginBottom: 0} } >Anexos</h5>
          </IconButton>
          ) }
          

          
        </Card>
        {isAttachmentModalOpen &&
          <Modal 
          isOpen={isAttachmentModalOpen && (openNotificationId === i) }
          handleClose={() => setIsAttachmentModalOpen(false)}
          title="Anexos"
          fullWidth={false}
          maxWidth="md"
          actions={false}
          
          children={() => {return (
            <NotificationAttachmentsModal
            notification={data}
            />
          ) }} >
          </Modal>
        }
        </Fragment>
      );
    });
  };
//
  return (
    <div ref={listRef}>
      {renderNotifications()}
      
          
    </div>
  );
}

export default Notifications;
