import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

export default [
  {
    component: LazyComponent(lazy(() => import('./List'))),
    path: '/campaign/list/',
  },
  {
    component: LazyComponent(lazy(() => import('./email-template/List'))),
    path: '/campaign/email-template/',
  },
  {
    component: LazyComponent(lazy(() => import('./email-senders/List'))),
    path: '/campaign/email-senders/',
  },
  {
    component: LazyComponent(lazy(() => import('./dashboard/Dashboard'))),
    path: '/campaign/dashboard/:id',
  },
];
