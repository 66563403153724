import {createContext, useContext} from 'react';
import * as NT from '@nimbus-target/ui';

const SocketContext = createContext();
const UserContext = createContext();
const ThemeContext = NT.NimbusThemeProvider;

function useSocketContext() {
  return useContext(SocketContext);
}

function useUserContext() {
  return useContext(UserContext);
}

function useThemeContext() {
  const {theme} = NT.useTheme();
  return theme;
}

export {
  SocketContext,
  UserContext,
  ThemeContext,
  useSocketContext,
  useUserContext,
  useThemeContext,
};
