import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

export default [
  {
    component: LazyComponent(lazy(() => import('./List'))),
    path: '/import/list',
  },
  {
    component: LazyComponent(lazy(() => import('./Import'))),
    path: '/import',
  },
];
