import {useEffect, useRef} from 'react';

/**
 * This hook may be used with caution.
 * Use this when you want to run something only after the first render but some data that you
 * need inside it is beign updated many times, so you can't place this variable inside
 * dependency array.
 *
 * e.g. UserContextProvider effect needs pathName in the moment of the first render
 * @param cb
 */
export function useDidMountEffect(cb) {
  useEffect(cb, []);
}

/**
 * Returns the previous value
 * @param value
 * @returns {*}
 */
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
