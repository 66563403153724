import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import socketIoClient from 'socket.io-client';
import {usePrevious} from 'utils/hooks';
import {SocketContext, useUserContext} from './index';

function SocketContextProvider(props) {
  const {children} = props;

  const [socketCore, setSocketCore] = useState(null);

  const {isAuthenticated} = useUserContext();
  const previousIsAuthenticated = usePrevious(isAuthenticated);

  useEffect(() => {
    // Avoid infinity loop due to socketCore changes
    if (isAuthenticated === previousIsAuthenticated) return;

    if (isAuthenticated) {
      const env = window.ENV ?? process.env;
      const domain = process.env.NODE_ENV === 'production'
        ? env.REACT_APP_DOMAIN_CORE
        : 'http://localhost:60000';
      const newSocketCore = socketIoClient.connect(domain, {
        query: {
          token: localStorage.getItem('jwt'),
        },
        secure: true,
      });
      setSocketCore(newSocketCore);
      return;
    }

    if (socketCore) {
      socketCore.disconnect();
      setSocketCore(null);
    }
  }, [isAuthenticated, previousIsAuthenticated, socketCore]);

  return (
    <SocketContext.Provider value={{socketCore}}>
      {children}
    </SocketContext.Provider>
  );
}

SocketContextProvider.propTypes = {
  children: PropTypes.node,
};

export default SocketContextProvider;
