import React, {useEffect, useState, Fragment} from 'react';
import {useHistory/*, useLocation*/} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useUserContext, useThemeContext} from 'context/index';
import {useDispatchPost} from 'redux/hooks/fetch';
import {useResetState} from 'redux/hooks';
import {useDispatchSetAppValue} from 'redux/hooks/app';

/* MATERIAL */
import {makeStyles} from '@material-ui/core/styles';
import {Link, Tooltip, Typography} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Button from 'components/buttons/Button';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import SignalWifi4BarOutlinedIcon from '@material-ui/icons/SignalWifi4BarOutlined';
import SignalWifi3BarOutlinedIcon from '@material-ui/icons/SignalWifi3BarOutlined';
import SignalWifi1BarOutlinedIcon from '@material-ui/icons/SignalWifi1BarOutlined';
import SignalWifiOffOutlinedIcon from '@material-ui/icons/SignalWifiOffOutlined';
import Input from 'components/inputs/Input';
import {useDispatchSetAppError, useDispatchSetAppSuccessMessage} from 'redux/hooks/app';
import PermissionToggle from 'components/permission/PermissionToggle';

import TourProfile from './../components/tour/TourProfile';

function PerfilBar(props) {
  const {handleClose} = props;

  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
    },
    userInfo: {
      height: 250,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    userPicture: {
      height: 150,
      width: 150,
      color: theme.palette.type === 'dark' ? theme.palette.supportOne : theme.palette.supportTwo,
    },
    usergroup: {
      color: theme.palette.primary.main,
      fontSize: 13,
      textTransform: 'capitalize',
    },
    buttonScreen: {
      marginBottom: theme.spacing(1.5),
      width: '100%',
      '& > button': {
        width: '100%',
      },
    },
    rowButtons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      borderCollapse: 'collapse',
      '& :nth-child(1)': {
        borderRadius: '20px 0px 0px 20px',
      },
      '& :nth-child(2)': {
        borderRadius: '0px 20px 20px 0px',
      },
    },
    toggleTheme: {
      marginTop: 'auto',
      marginBottom: 25,
    },
    buttonTheme: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      '& > svg': {
        height: 15,
      },
    },
    screenContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      '& > *': {
        marginBottom: theme.spacing(1.5),
      },
    },
    connectionHigh: {
      height: 18,
      width: 18,
      marginRight: 10,
      color: theme.palette.success.dark,
    },
    connectionMedium: {
      height: 18,
      width: 18,
      marginRight: 10,
      color: '#eeb407',
    },
    connectionLow: {
      height: 18,
      width: 18,
      marginRight: 10,
      color: '#eeb407',
    },
    connectionFalse: {
      height: 18,
      width: 18,
      marginRight: 10,
      color: theme.palette.type === 'dark' ? theme.palette.supportOne : theme.palette.supportTwo,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }));

  const classes = useStyles();

  const {handleSubmit, register} = useForm({});
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [disableTheme/*, setDisableTheme*/] = useState(false);

  const {resetToken, _id, nickname, name, email, groupType, setUserData} = useUserContext();
  const {palette, isThemeAuto, changeTheme} = useThemeContext();

  const {isMobile} = useThemeContext();

  const history = useHistory();
  // const {pathname} = useLocation();
  const resetState = useResetState();

  const [perfilScreen, setPerfilScreen] = useState('home');

  const [connectionSpeed, setConnectionSpeed] = useState(null);

  const post = useDispatchPost();

  const setAppSuccess = useDispatchSetAppSuccessMessage();
  const setAppError = useDispatchSetAppError();

  const setUserTerms = useDispatchSetAppValue('userTerms');
  const [hasUserTerms, setHasUserTerms] = useState(false);

  // connection speed indicator
  useEffect(() => {
    let interval = null;
    if (navigator && navigator.connection) {
      if (!navigator.onLine) setConnectionSpeed(0);
      else setConnectionSpeed(navigator.connection.downlink);
      interval = setInterval(() => {
        if (!navigator.onLine) setConnectionSpeed(0);
        else setConnectionSpeed(navigator.connection.downlink);
      }, [10000]);
    }
    return () => { if (interval) clearInterval(interval) }; // clear
  }, []);

  // disable theme change outside of chat
  // useEffect(() => {
  // if (!pathname.includes('/chat') && !pathname.includes('/home') && pathname !== '/') {
  // setDisableTheme(true);
  // } else {
  // setDisableTheme(false);
  // }
  // }, [pathname]);

  // check has terms
  useEffect(() => {
    post('/user-terms/hasactive').then((responseData) => {
      console.info(responseData);
      if (responseData.data > 0) {
        setHasUserTerms(true)
      }
    }).catch(err => console.error(err));
  }, [setHasUserTerms, post]);

  const handleTheme = (_evt, newTheme) => {
    changeTheme(newTheme);
    function gtag() { window.dataLayer.push(arguments); }
    gtag('event', 'change_theme', {
      theme: newTheme,
    });
  };

  const handleLogout = () => {
    handleClose();
    resetState();
    resetToken();
    history.push('/login');
  };

  const submitInfo = (data) => {
    console.log(data);
    setIsSubmiting(true);
    post('/user/update-my-info', {
      ...data,
      id: _id,
    })
      .then((responseData) => {
        console.log(responseData);

        setAppSuccess(
          perfilScreen === 'updateData' ?
            'Seus dados foram alterados com sucesso' :
            'Sua senha foi alterada com sucesso',
        );

        setUserData(responseData.data);
        setPerfilScreen('home');
        setIsSubmiting(false);
      })
      .catch((err) => {
        console.log(err);
        setAppError(
          perfilScreen === 'updateData' ?
            'Houve um erro ao alterar seus dados, por favor tente novamente' :
            'Houve um erro ao alterar sua senha, por favor tente novamente',
        );
        setIsSubmiting(false);
      })
  }

  const renderConnectionIndicator = () => {
    if (!connectionSpeed) return (<div />);
    if (connectionSpeed > 5) {
      return (
        <Tooltip title="Conexão Ótima">
          <SignalWifi4BarOutlinedIcon className={classes.connectionHigh} />
        </Tooltip>
      )
    }
    if (connectionSpeed > 1) {
      return (
        <Tooltip title="Conexão Boa">
          <SignalWifi3BarOutlinedIcon className={classes.connectionMedium} />
        </Tooltip>
      )
    }
    if (connectionSpeed > 0) {
      return (
        <Tooltip title="Conexão Lenta">
          <SignalWifi1BarOutlinedIcon className={classes.connectionLow} />
        </Tooltip>
      )
    }
    if (connectionSpeed === 0) {
      return (
        <Tooltip title="Sem Internet">
          <SignalWifiOffOutlinedIcon className={classes.connectionFalse} />
        </Tooltip>
      )
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.userInfo}>
        <PersonIcon className={classes.userPicture} />
        <div>
          {renderConnectionIndicator()}
          <span>{nickname || name}</span>
        </div>
        <span className={classes.usergroup}>{groupType}</span>
      </div>
      {/* options */}
      {perfilScreen === 'home' && (
        <Fragment>
          {isMobile && (
            <Fragment>
              <Link href="/home" className={classes.buttonScreen}>
                <Button variant="outlined">
                  Home
                </Button>
              </Link>
              <Link href="/chat" className={classes.buttonScreen}>
                <Button variant="outlined">
                  Atendimento
                </Button>
              </Link>
            </Fragment>
          )}
          <div className={classes.rowButtons}>
            <Button disabled={false} variant="outlined" className={classes.buttonScreen} handleClick={() => setPerfilScreen('updateData')}>
              Alterar Dados
            </Button>
            <Button disabled={false} variant="outlined" className={classes.buttonScreen} handleClick={() => setPerfilScreen('updatePass')}>
              Alterar Senha
            </Button>
          </div>

          <PermissionToggle permissionKey="canOpenSupportTicket" hasFeedback={false}>
            <Button disabled={false} variant="outlined" className={classes.buttonScreen} handleClick={() => { history.push('/ticket/list'); handleClose() }}>
              Suporte
            </Button>
          </PermissionToggle>

          <Button disabled={false} variant="outlined" className={classes.buttonScreen} handleClick={() => { history.push('/user/set-signature'); handleClose() }}>
            Personalizar assinatura
          </Button>


          <Button variant="outlined" className={classes.buttonScreen} handleClick={handleLogout}>
            Sair
          </Button>

          {/* <Divider orientation="middle" />
          <Button variant="outlined" className={classes.buttonScreen} handleClick={() => setPerfilScreen('tour')}>
            Tour pela plataforma
          </Button> */}

          <div className={classes.toggleTheme}>
            <ToggleButtonGroup
              value={isThemeAuto ? 'auto' : palette.type}
              size="small"
              exclusive={true}
              onChange={handleTheme}
              aria-label="trocar tema"
            >
              <ToggleButton disabled={disableTheme} value="light" fontSize="small" size="small" aria-label="claro">
                <div className={classes.buttonTheme}>
                  <BrightnessHighIcon />
                  <span>Claro</span>
                </div>
              </ToggleButton>
              <ToggleButton disabled={disableTheme} value="dark" fontSize="small" aria-label="escuro">
                <div className={classes.buttonTheme}>
                  <Brightness1Icon />
                  <span>Escuro</span>
                </div>
              </ToggleButton>
              <ToggleButton disabled={disableTheme} value="auto" fontSize="small" aria-label="automático">
                <div className={classes.buttonTheme}>
                  <BrightnessAutoIcon />
                  <span>Automático</span>
                </div>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Fragment>
      )}
      {perfilScreen === 'tour' && (
        <div className={classes.screenContent}>
          <TourProfile />
          <Button variant="outlined" handleClick={() => setPerfilScreen('home')} className={classes.buttonScreen}>
            Voltar
          </Button>
        </div>
      )}

      {perfilScreen === 'updateData' && (
        <form
          className={classes.screenContent}
          onSubmit={handleSubmit(submitInfo)}>
          <Input name="name" label="Nome" defaultValue={name} ref={register({required: true})} />
          <Input name="nickname" label="Apelido" defaultValue={nickname} ref={register} />
          <Input name="email" label="E-mail" type="email" defaultValue={email} ref={register({required: true})} />
          <Button variant="outlined" color="primary" type="submit" disabled={isSubmiting} handleClick={() => { }}>
            Confirmar
          </Button>
          <Button variant="outlined" handleClick={() => setPerfilScreen('home')}>
            Voltar
          </Button>
        </form>
      )}
      {perfilScreen === 'updatePass' && (
        <form
          className={classes.screenContent}
          onSubmit={handleSubmit(submitInfo)}>
          <Input name="password" label="Nova Senha" type="password" autoComplete="new-password" ref={register({required: true})} />
          <Button variant="outlined" color="primary" type="submit" disabled={isSubmiting} handleClick={() => { }}>
            Confirmar
          </Button>
          <Button variant="outlined" handleClick={() => setPerfilScreen('home')}>
            Voltar
          </Button>
        </form>
      )}

      {hasUserTerms && (
        <Typography variant="caption" color="secondary">
          <a href="#termos" onClick={() => setUserTerms(true)} className={classes.buttonScreen}>
            Termos de uso
          </a>
        </Typography>
      )}


      {/* release footer */}
      <Typography variant="caption" color="secondary">
        <a href="mailto:luciana.costa@nt.digital">
          Contato por E-mail
        </a>
      </Typography>
      <Typography variant="caption" color="secondary">Copyright © Nimbus 2022 - 0.0.90</Typography>
    </div>
  );
}

export default PerfilBar;
