/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React, {
  Fragment,
  useState,
} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button as MaterialButton, Tooltip} from '@material-ui/core';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

// Customize material button styles
const MaterialCustomButton = withStyles({
  root: {
    boxShadow: 'none',
    fontSize: 12,
    fontWeight: 500,
    padding: '10px 16px',
    lineHeight: 1.5,
    borderRadius: '19.5px',
    fontFamily: 'Montserrat',
  },
})(MaterialButton)

// Customize material tooltip styles
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

function Button(props) {
  const {
    alt,
    altPosition,
    children,
    className,
    disabled,
    handleClick,
    form,
    type,
    variant,
    color,
    size,
    iconLeft,
    iconRight,
    ...htmlProps
  } = props;

  // Control tooltip
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      <LightTooltip
        open={alt && isOpen ? true : false}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        title={alt || ''}
        placement={altPosition}
      >
        <MaterialCustomButton
          {...htmlProps}
          disableRipple={true}
          variant={variant}
          disabled={disabled}
          color={color}
          size={size}
          startIcon={iconLeft}
          endIcon={iconRight}
          form={form}
          className={cn(className, {
            [styles.gradient]: color === 'gradient',
            [styles.secondary]: color === 'secondary',
          })}
          onClick={handleClick}
          type={type}
        >
          {children}
        </MaterialCustomButton>
      </LightTooltip>
    </Fragment>
  );
}

Button.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  form: PropTypes.string,
  handleClick: PropTypes.func,
  size: PropTypes.string,
  startIcon: PropTypes.node,
  type: PropTypes.string,
  variant: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  type: 'button',
};

export default Button;
