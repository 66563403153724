import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core';
import IconButton from 'components/buttons/IconButton';
import StarIcon from "@material-ui/icons/Star";


function FavoriteLeadsButton(props) {
  const {setDrawer} = props;

  const useStyles = makeStyles((theme) => ({
    icon: {
      fontSize: 28,
      [theme.breakpoints.down("1300")]: {
        fontSize: 22,
      },
      [theme.breakpoints.down("1100")]: {
        fontSize: 20,
      },
    },
    badge: {
      background: theme.palette.success.main,
    },
    moreIconsButtons: {
      color: 'rgba(0,0,0,0.6) !important',
      fontSize: '1.25rem',
      '& span': {
        '& svg': {
          marginRight: 10,
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <Fragment>
      <IconButton
        alt="Leads favoritos"
        handleClick={() => {
          setDrawer(true);
          function gtag(){window.dataLayer.push(arguments);}
          gtag('event', 'favorite-leads-button');
        }}
        data-tour="tour_home_menu_favorites"
        className={classes.moreIconsButtons}
      >
        <StarIcon className={classes.icon} />
        Leads favoritos
      </IconButton>
    </Fragment>
  );
}

export default FavoriteLeadsButton;
