import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

// TODO - adequar o serviceWorker para uso
serviceWorker.unregister();
