import React, {useEffect} from 'react';
import {useAppValue, useDispatchResetAppFeedback} from 'redux/hooks/app';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function FeedbackMessage() {
  const feedback = useAppValue('feedback');
  const {message, type} = feedback || {};

  const resetFeedback = useDispatchResetAppFeedback();

  useEffect(() => {
    if (message && type) {
      function gtag(){window.dataLayer.push(arguments);}
      gtag('event', 'feedback_message', {
        feedback_type: type,
        feedback_message: message,
      });
    }
    // if (message) {
    //   setTimeout(() => {
    //     resetFeedback();
    //   }, 8000);
    // }
  }, [message, resetFeedback, type]);

  const handleClick = () => {
    resetFeedback();
  };

  if (!message) return null;
  return (
    <Snackbar
      open={true}
      onClose={handleClick}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      autoHideDuration={8000}
    >
      <Alert severity={type} onClose={handleClick}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default FeedbackMessage;
