import React from 'react';
import PropTypes from 'prop-types';
import {Accordion as MaterialAccordion, AccordionDetails, AccordionSummary, Badge, makeStyles, Typography} from '@material-ui/core';

function Accordion(props) {
  const {
    children,
    expandIcon,
    title,
    bullet,
    defaultExpanded,
    titleColor,
  } = props

  const useStyles = makeStyles((theme) => ({
    accordionBack: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
    },
    "&.MuiAccordion-root::before": {
      backgroundColor: 'transparent',
    },
    title: {
      color: titleColor || '#A7ABBA',
    },
  }));

  const classes = useStyles();

  return (
    <MaterialAccordion
      defaultExpanded={defaultExpanded || false}
      className={classes.accordionBack}
    >
      <AccordionSummary
        expandIcon={expandIcon}
      >
        {bullet &&
          <Badge
            color="primary"
            variant="dot"
            overlap="circular"
            badgeContent=""
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            style={{left: -20, top: 7}}
          />
        }
        <Typography className={classes.title}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </MaterialAccordion>
  )
}

Accordion.propTypes = {
  bullet: PropTypes.bool,
  children: PropTypes.node,
  expandIcon: PropTypes.node,
  title: PropTypes.string,
};

export default Accordion;
