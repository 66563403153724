import PropTypes from 'prop-types';
import {useFeatureToggle} from 'redux/hooks/app';

function FeatureToggle(props) {
  const {children, configKey} = props;
  // TODO: console.warn quando nao existir a key na config. Ou mandar pro bugsnag
  const {showFeature} = useFeatureToggle(configKey);
  if (!showFeature) return null;
  return children;
}

FeatureToggle.propTypes = {
  configKey: PropTypes.string.isRequired, // TODO: explicar
};

export default FeatureToggle;
