// modules
import React, {useRef, useEffect, useState, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Button, Card, CardContent, Divider, makeStyles, Popover, Slider, Tooltip, Typography} from "@material-ui/core";
import {Skeleton} from '@material-ui/lab';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import CallEndIcon from '@material-ui/icons/CallEnd';
import MicOffIcon from '@material-ui/icons/MicOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory, useLocation} from 'react-router-dom';

// components
import IconButton from 'components/buttons/IconButton';

// functions
import {useAppValue, useDispatchSetAppError, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import {InfoOutlined, VolumeDown, VolumeUp} from '@material-ui/icons';
import {useSocketContext, useUserContext} from 'context';
import CheckBox from 'components/inputs/CheckBox';

function Dialer() {

  const {groupSalesId} = useUserContext();
  const {socketCore} = useSocketContext();
  const history = useHistory();

  const {
    register,
    control,
    watch,
  } = useForm();

  const setSipcallPluginHandle = useDispatchSetAppValue('VOIP_SipcallPluginHandle');
  const sipcallPluginHandle = useAppValue('VOIP_SipcallPluginHandle');

  const Janus = useRef(window.Janus);
  const ua = useRef(Janus.current || null);
  const sipcall = useRef(sipcallPluginHandle || null);
  const audioRemote = useRef(document.getElementById('audio_remote'));
  const ringbacktone = useRef(window.ringbacktone);
  const ringtone = useRef(window.ringtone);
  const turnOffBubble = useRef(window.turn_off_bubble);
  // cancel call option
  const cancelCallRequest = useRef(false);
  // current lead talking data
  const currentLeadDataTalking = useAppValue('VOIP_DIALER_currentLeadDataTalking');
  const setCurrentLeadDataTalking = useDispatchSetAppValue('VOIP_DIALER_currentLeadDataTalking');

  const [incoming, setIncoming] = useState(null);
  const [incomingJSEP, setIncomingJSEP] = useState(null);

  const handshake = useAppValue('VOIP_handshake');
  const setHandshake = useDispatchSetAppValue('VOIP_handshake');
  const registered = useAppValue('VOIP_registered');
  const setRegistered = useDispatchSetAppValue('VOIP_registered');

  const disconnected = useAppValue('VOIP_disconnected');
  const setDisconnected = useDispatchSetAppValue('VOIP_disconnected');
  
  const [mute, setMute] = useState(false);
  const [volume, setVolume] = useState(100); // 0; 10; 20; 30; [...]; 100
  const [lastVolume, setLastVolume] = useState(100); // when to use silence and not silence in sequence

  const [contadorTentativaConexao, setContadorTentativaConexao] = useState(0);

  const [leadListCount, setLeadListCount] = useState(null);
  const [callNumbers, setCallNumbers] = useState(null); 
  const [statusNamesData, setStatusNamesData] = useState('');
  const [statusIdsFilter, setStatusIdsFilter] = useState(null);
  const [regressiveCounter, setRegressiveCounter] = useState(null);

  const [helpOpen, setHelpOpen] = useState(false);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);

  const post = useDispatchPost();
  const setAppError = useDispatchSetAppError();

  // init status 
  const initStatus = useAppValue('VOIP_initStatus');
  const setInitStatus = useDispatchSetAppValue('VOIP_initStatus');

  // states to call
  const voipDialerState = useAppValue('VOIP_DIALER_callStatus');
  const setVoipDialerState = useDispatchSetAppValue('VOIP_DIALER_callStatus');

  // finished state
  const voipDialerCurrentCallFinished = useAppValue('VOIP_DIALER_currentCallFinished');
  const setVoipDialerCurrentCallFinished = useDispatchSetAppValue('VOIP_DIALER_currentCallFinished');

  const setVoipIsActive = useDispatchSetAppValue('VOIP_isActiveComponent');

  const voipModal = useAppValue('voip_modal');
  
  const {pathname} = useLocation();
  const voipEmbedded = pathname.includes('/webphone');

  const bina = useAppValue('VOIP_bina');
  const binaIsValid = useAppValue('VOIP_binaIsValid');

  const setBina = useDispatchSetAppValue('VOIP_bina');
  const setBinaIsValid = useDispatchSetAppValue('VOIP_binaIsValid');
  const setRamalNumber = useDispatchSetAppValue('VOIP_ramalNumber');
  
  const host = useAppValue('voip_host');
  const setHost = useDispatchSetAppValue('voip_host');
  const username = useAppValue('voip_username');
  const setUsername = useDispatchSetAppValue('voip_username');
  const secret = useAppValue('voip_secret');
  const setSecret = useDispatchSetAppValue('voip_secret');

  const ownRamal = useAppValue('voip_own_ramal');
  const setOwnRamal = useDispatchSetAppValue('voip_own_ramal');

  const setisTwilioVoip = useDispatchSetAppValue('is_twilio_voip_provider');

  const setSelectedLeadLoading = useDispatchSetAppValue('selectedLeadLoading');
  const setConversationLoading = useDispatchSetAppValue('conversationLoading');
  const setConversationMessages = useDispatchSetAppValue('conversationMessages');
  const setConversationTotal = useDispatchSetAppValue('conversationTotal');
  const setSelectedLeadData = useDispatchSetAppValue('selectedLeadData');

  // voice-sip.zenvia.com // Zenvia Host
  // 8c498b2aace38b8340567d_at_nt.digital // Zenvia username
  // Henrique@987 // Zenvia secret

  // sip.nexmo.com // Vonage Host
  // ed798e6f // Vonage username
  // iVc7n2TKXs6ZBfwY // Vonage secret

  // nimbusdigital.sip.twilio.com // Twilio Host
  // henrique.dias // Twilio username
  // Henrique@987 // Twilio secret

  useEffect(() => {
    if (!host) getRamal();
    return () => false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host]);

  const getRamal = () => {
    post('/voip-ramal/get-ramal')
      .then((response) => {
        if (response.data) {
          const ramal = response.data;
          setOwnRamal(!!ramal.userId);
          setHost(ramal.host);
          setUsername(ramal.username);
          setSecret(ramal.secret);
          setBina(ramal.bina);
          setBinaIsValid(ramal.binaIsValid);
          setRamalNumber(ramal.ramalNumber);
          if (ramal.type === 'twilio') {
            setisTwilioVoip(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  const createCall = () => {
    // Create call queue records
    post(
      '/voip-call/create-dialer-call',
      {
        statusIdsFilter: statusIdsFilter,
        ramalUsername: username, // Only twilio can make multiple calls
        groupSalesId,
      },
    )
      .then((responseData) => {
        // Set regressive counter
        setRegressiveCounter(5);
        // Wait some seconds before init call
        setTimeout(() => {
          console.log("CANCELOU?", cancelCallRequest.current)
          // If user canceled, just return
          if (cancelCallRequest.current === true) {
            // Reset cancel state
            cancelCallRequest.current = false;
          } else {
            // Pass the first number of 5 to init calls
            startCall(responseData.data.firstNumberToInitCall);
          }
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setAppError("Houve um erro ao iniciar as ligações, tente novamente em alguns minutos");
      });
  }

  const switchStatus = useMemo(() => {
    if (registered && (voipDialerState !== 'onCall' && voipDialerState !== 'dialing' && voipDialerState !== 'ringing')) {
      return 'ready';
    } else if (registered && (voipDialerState === 'onCall' || voipDialerState === 'dialing' || voipDialerState === 'ringing')) {
      return 'incoming';
    } else if (!registered) {
      return 'unregistered';
    };
  }, [registered, voipDialerState]);

  useEffect(() => {
    if (switchStatus === 'ready' || switchStatus === 'incoming') {
      // Get new and attempt status names
      post('/status/list', {type: ['new', 'attempt']}).then((responseStatusData) => {
        // Format statusId filter
        let statusIdFilter = responseStatusData.data.map(statusData => statusData._id);
        // Set state to use later
        setStatusIdsFilter(statusIdFilter);
        // Count leads
        post('/lead/count', {groupSalesId, statusId: statusIdFilter, onlyWithPhone: true}).then((responseCountData) => {
          // Set leads nuimber
          setLeadListCount(responseCountData.data);
          // Aux
          let statusNames = '';
          // For each one, get names and format
          for (let idx = 0; idx < responseStatusData.data.length; idx++) {
            const status = responseStatusData.data[idx];
            if (statusNames && idx + 1 === responseStatusData.data.length) {
              statusNames = statusNames + ' e ' + status.name
            } else if (statusNames) {
              statusNames = statusNames + ', ' + status.name
            } else {
              statusNames = status.name
            }
          }
          // Set
          setStatusNamesData(statusNames);
          // Get call numbers
          post('/voip-call/count-dialer-call', {ramalUsername: username, groupSalesId, statusIdFilter}).then((responseCallData) => {
            setCallNumbers(responseCallData.data);
          }).catch((err) => {
            console.log(err);
            setAppError("Algo deu errado ao tentar buscar os números das suas últimas ligações, feche o discador e abra novamente");
          })
        }).catch((err) => {
          console.log(err);
          setAppError("Algo deu errado ao tentar buscar a quantidade de leads disponível, feche o discador e abra novamente");
        });
      }).catch((err) => {
        console.log(err);
        setAppError("Houve um erro ao buscar os status de atendimento, feche o discador e abra novamente");
      });
    } else {
      console.log("Dicador ainda não está pronto: ", switchStatus);
    }
  }, [post, switchStatus, groupSalesId, username, setAppError]);

  useEffect(() => {
    if (voipDialerState === 'onCall' || voipDialerState === 'dialing' || voipDialerState === 'ringing') {
      setVoipIsActive(true);
    } else {
      setVoipIsActive(false);
    }
  }, [setVoipIsActive, voipDialerState]);

  // IMPORTANT: Treatment for when lead hang up the call while user has the voip dialer drawer closed
  useEffect(() => {
    if (voipDialerCurrentCallFinished === true && voipDialerState === 'onCall') {
      // It means call was finished by the lead who accepted, so we hang up too
      var hangup = {request: 'hangup'};
      sipcall.current.send({message: hangup});
      sipcall.current.hangup();
      // stop ringtone
      stopRingbackTone();
      stopRingTone();
      // Clear lead data
      setCurrentLeadDataTalking(null);
      // reset state
      setVoipDialerState('');
      // Just reset state
      setVoipDialerCurrentCallFinished(false);
    }
  }, [voipDialerCurrentCallFinished, voipDialerState, setVoipDialerCurrentCallFinished, setVoipDialerState, setCurrentLeadDataTalking]);

  const registerSIP = () => {
    var register = {
      request: 'register',
      username: `sip:${username}@${host}`,
    };
  
    register['secret'] = secret;
    register['proxy'] = `sip:${host}`;
    sipcall.current.send({message: register});
  };

  function startRingbackTone() {
    try {
      ringbacktone.current.play();
    } catch (e) {}
  }
  
  function stopRingbackTone() {
    try {
      ringbacktone.current.pause();
    } catch (e) {}
  }
  
  function startRingTone() {
    try {
      ringtone.current.play();
    } catch (e) {}
  }
  
  function stopRingTone() {
    try {
      ringtone.current.pause();
    } catch (e) {}
  }

  function bubbleTone(volume = 50) {
    try {
      turnOffBubble.current.volume = volume / 100;
      turnOffBubble.current.play();
    } catch (e) {}
  }

  const startCall = (firstNumberToCall) => {
    if (incoming) {
      sipcall.current.createAnswer({
        jsep: incomingJSEP,
        media: {audio: true, video: false},
        success: function (jsep) {
          Janus.current.debug('Got SDP! audio=' + true + ', video=' + false);
          Janus.current.debug(jsep);
          var body = {request: 'accept'};
          sipcall.current.send({message: body, jsep: jsep});

          chamadaConectou();
        },
        error: function (error) {
          Janus.current.error('WebRTC error:', error);

          var body = {request: 'decline', code: 480};
          sipcall.current.send({message: body});
        },
      });
    } else {
      if (handshake) {
        // remove mute and silence
        sipcall.current.unmuteAudio();
        setMute(false);
        audioRemote.current.volume = 1;
        setVolume(100);

        var callTo = `sip:55${firstNumberToCall}@${host}`; // Vonage; use contry code (e.g 5511986759008)
    
        sipcall.current.createOffer({
          media: {
            audioSend: true,
            audioRecv: true, // We DO want audio
            videoSend: false,
            videoRecv: false, // We MAY want video
          },
          success: function (jsep) {
            startRingbackTone();
            var body = {request: 'call', uri: callTo, autoack: false};
            sipcall.current.send({message: body, jsep: jsep});

            setVoipDialerState('dialing');
          },
          error: function (error) {
            if (error === 'No capture device found') {
              alert('Microfone não encontrado');
            }
            alert('WebRTC error... ' + JSON.stringify(error));
          },
        });
      }
    
      if (!handshake) {
        console.log('not connected');
      }
    }
  };

  function doHangup() {
    var hangup = '';
    if (voipDialerState === 'ringing') {
      if (incoming) {
        console.log('IS RINGING: - decline call\n\n');
        hangup = {request: 'decline'};
      } else {
        console.log('RINGING True: HANGUP\n');
        hangup = {request: 'hangup'};
      }
    } else {
      console.log('RINGING False: HANGUP\n');
      hangup = {request: 'hangup'};
    }

    sipcall.current.send({message: hangup});
    sipcall.current.hangup();

    // stop ringtone
    stopRingbackTone();
    stopRingTone();

    // Clear lead data
    setCurrentLeadDataTalking(null);

    // reset state
    setVoipDialerState('')

    // Auto call the next 5 ones?
    if (
      watch('autoCallNext5') === true
      && callNumbers
      && leadListCount
      && leadListCount - callNumbers.freezed > 0
    ) {
      createCall();
    }
  }

  const handleDisconnect = (err) => {
    console.log(err);
    setDisconnected(true);
    setRegistered(false);
    setVoipDialerState("");
    setIncoming(false);
  }

  function handleMute() {
    bubbleTone();
    if (!mute) {
      sipcall.current.muteAudio();
      setMute(true);
    } else {
      sipcall.current.unmuteAudio();
      setMute(false);
    }
  }
  
  function handleVolume(volume) {
    bubbleTone(volume);
    setVolume(volume);
    audioRemote.current.volume = volume / 100;
  }
  
  function chamadaConectou() {
    if (window.btnBFCP) window.btnBFCP.disabled = false;
    stopRingbackTone();
    stopRingTone();

    setVoipDialerState('onCall');
  }

  const handleCancelDialer = () => {
    // Cancel everything
    cancelCallRequest.current = true;
    // Cancel all requests on backend
    post(
      '/voip-call/cancel-dialer-call',
      {
        ramalUsername: username,
      },
    ).then(() => {
      
    }).catch((err) => {
      console.log(err);
      setAppError("Não foi possível cancelar as ligações, tente novamente.");
    });
  }

  const init = () => {
    Janus.current.init({
      debug: true,
      callback: function() {
        ua.current = new Janus.current({
          server: 'https://voice-gw05.zenvia.com/webphone3',
          // server: "wss://janus.conf.meetecho.com/ws", // estudar qual o melhor server para utilizar, ou criar o nosso? qualquer um tem servido
          destroyOnUnload: false,
          iceServers: [
            {urls: 'stun:turn-server.totalsip.com.br:3478'},
            {
              urls: 'turn:turn-server.totalsip.com.br',
              credential: 'zenviaturn',
              username: 'totalvoiceturn',
            },
            {urls: 'stun:stun.l.google.com:19302'},
          ],
          success: () => {
            setHandshake(true);

            ua.current.attach({
              plugin: 'janus.plugin.sip',
              opaqueId: 'siptest-' + Janus.current.randomString(12),
              success: (pluginHandle) => {
                sipcall.current = pluginHandle;
                Janus.current.log('Plugin attached! (' + sipcall.current.getPlugin() + ', id=' + sipcall.current.getId() + ')');

                registerSIP();

                // say to everyone that Janus was started successfully
                setInitStatus(true);
                // save plugin handle object, so we can use later anywhere if we neeed
                setSipcallPluginHandle(pluginHandle);
              },
              error: (error) => {
                Janus.current.error('  -- Error attaching plugin...', error);
              },
              consentDialog: (on) => {
                Janus.current.debug('Consent dialog should be ' + (on ? 'on' : 'off') + ' now');
              },
              onmessage: (msg, jsep) => {
                Janus.current.debug(' ::: Got a message :::');
                Janus.current.debug(JSON.stringify(msg));

                var error = msg['error'];
                if (error !== null && error !== undefined) {
                  if (!registered) {
                  } else {
                    sipcall.current.hangup();
                  }

                  Janus.current.debug(' ::: ERRORRR :::');
                  return;
                }
                var result = msg['result'];
                if (
                  result !== null &&
                  result !== undefined &&
                  result['event'] !== undefined &&
                  result['event'] !== null
                ) {
                  var event = result['event'];
                  if (event === 'registration_failed') {
                    stopRingbackTone();
                    //setTimeout(function(){
                    //    window.location.reload(true);
                    //}, 7000);
                    handleDisconnect();
                  }
                  if (event === 'registered') {
                    Janus.current.log(
                      'Successfully registered as ' +
                        result['username'] +
                        '!');
                    setRegistered(true);
                    setDisconnected(false); // remove disconnect
                  } else if (event === 'calling') {
                    Janus.current.log('Waiting for the peer to answer...');
                  } else if (event === 'incomingcall') {
                    setIncoming(true);
                    setIncomingJSEP(jsep);
                    Janus.current.log('Incoming call from ' + result['displayname'] + ' - ' + result['sip_from'] + ' - ' + result['username']);
                    var doAudio = true, doVideo = false;
                    if (jsep !== null && jsep !== undefined) {
                      // What has been negotiated?
                      doAudio = jsep.sdp.indexOf('m=audio ') > -1;
                      doVideo = jsep.sdp.indexOf('m=video ') > -1;
                      Janus.current.debug('Audio ' + (doAudio ? 'has' : 'has NOT') + ' been negotiated');
                      Janus.current.debug('Video ' + (doVideo ? 'has' : 'has NOT') + ' been negotiated');
                    }
                    // eslint-disable-next-line no-unused-vars
                    var rtpType = '';
                    var srtp = result['srtp'];
                    if (srtp === 'sdes_optional')
                      rtpType = ' (SDES-SRTP offered)';
                    else if (srtp === 'sdes_mandatory')
                      rtpType = ' (SDES-SRTP mandatory)';

                    // TODO notifica o usuario aqui de chamada chegando
                    startRingTone();
                    setVoipDialerState('ringing');
                  } else if (event === 'accepting') {
                    // Response to an offerless INVITE, let's wait for an 'accepted'
                  } else if (event === 'progress') {
                    Janus.current.log('ESTADO:' + voipDialerState + '\n\n');
                    if (voipDialerState == null) {
                      setContadorTentativaConexao(contadorTentativaConexao + 1);
                      if (contadorTentativaConexao < 5) {
                        // TODO reconnect
                        // sipRegister();
                      } else {
                        // recarrega as configs
                        setTimeout(function () {
                          // window.location.reload(true);
                        }, 1000);
                      }
                      //displayDesconectado(false, error, error, error);
                      //setTimeout(function(){
                      //    window.location.reload(true);
                      //}, 1000);
                    } else {
                      setVoipDialerState('ringing');
                      stopRingbackTone();
                      Janus.current.log(
                        "There's early media from " +
                          result['username'] +
                          ', wairing for the call!');
                      Janus.current.log(jsep);
                      // Call can start already: handle the remote answer
                      if (jsep !== null && jsep !== undefined) {
                        sipcall.current.handleRemoteJsep({
                          jsep: jsep,
                          error: doHangup,
                        });
                      }
                      Janus.current.log('Early media...');
                    }
                  } else if (event === 'accepted') {
                    //result["username"]
                    Janus.current.log(result['username']);
                    // TODO Video call can start
                    if (
                      jsep !== null &&
                      jsep !== undefined &&
                      voipDialerState !== 'ringing'
                    ) {
                      sipcall.current.handleRemoteJsep({
                        jsep: jsep,
                        error: doHangup,
                      });
                    }

                    chamadaConectou();
                  } else if (event === 'hangup') {
                    setIncoming(false);
                    Janus.current.log(
                      'Call hung up (' +
                        result['code'] +
                        ' ' +
                        result['reason'] +
                        ')!');

                    doHangup();
                  }
                }
              },
              onlocalstream: function (stream) {
                Janus.current.debug(' ::: Got a local stream :::');
                Janus.current.debug(JSON.stringify(stream));
                // Janus.attachMediaStream(audioRemote, stream);
              },
              onremotestream: function (stream) {
                Janus.current.debug(' ::: Got a remote stream :::');
                Janus.current.debug(JSON.stringify(stream));
                Janus.current.attachMediaStream(audioRemote.current, stream);
              },
              oncleanup: function () {
                Janus.current.log(' ::: Got a cleanup notification :::');
                setIncoming(false);
              },
            });
          },
          error: (error) => {
            handleDisconnect(error);
          },
          destroyed: (error) => {
            handleDisconnect(error);
          },
        });
      },
    });
  };

  useEffect(() => {
    // if open voipModal and not found own ramal
    // create own ramal
    if (!ownRamal) {
      post('/voip-ramal/create-user-ramal')
        .then((response) => {
          if (response.data) {
            const ramal = response.data;
            setHost(ramal.host);
            setUsername(ramal.username);
            setSecret(ramal.secret);
            setBina(ramal.bina);
            setBinaIsValid(ramal.binaIsValid);
            setRamalNumber(ramal.ramalNumber);

            // reset register
            if (ua.current) ua.current.destroy();
            ua.current = null;
            setInitStatus(false);

            setOwnRamal(!!ramal.userId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [ownRamal, post, setOwnRamal, setBina, setBinaIsValid, setHost, setRamalNumber, setSecret, setUsername, setInitStatus, voipModal]);

  useEffect(() => {
    // wait load ramal info
    if (!host || !username || !secret) return;
    // do not repeat init
    if (initStatus) {
      console.log("Janus is ready already, double init prevented");
      setRegistered(true);
      setDisconnected(false); // remove disconnect
      return;
    }
    
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host, secret, username]);

  // Voip Dialer regressive counter
  useEffect(() => {
    if (!regressiveCounter || regressiveCounter === 0 || cancelCallRequest.current === true) {
      setRegressiveCounter(null);
      return;
    };
    regressiveCounter && regressiveCounter > 0 && setTimeout(() => setRegressiveCounter(regressiveCounter - 1), 1000);
  }, [regressiveCounter]);

  // Socket events (Call answered - change lead)
  useEffect(() => {
    if (!socketCore) return;

    const handleAnsweredCall = (leadData) => {
      if (!leadData) return;
      // Force stop ringtone
      stopRingbackTone();
      stopRingTone();
      // Set lead data to show who the sales is talking to
      setCurrentLeadDataTalking(leadData);
      // Clear chat
      setConversationLoading(true);
      setConversationMessages(null);
      setConversationTotal(0);
      // Clear lead
      setSelectedLeadLoading(true);
      setSelectedLeadData({});
      // Go to the lead
      history.push(`/chat/${leadData.leadId}`);
    };
    
    socketCore.on('voip-dialer-answered-call', handleAnsweredCall);
    return () => {
      socketCore.removeListener('voip-dialer-answered-call', handleAnsweredCall);
    };
  }, [socketCore, history, setCurrentLeadDataTalking, setConversationLoading, setConversationMessages, setConversationTotal, setSelectedLeadLoading, setSelectedLeadData]);

  const useStyles = makeStyles((theme) => ({
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& svg': {
        height: 32,
        width: 32,
      },
    },
    status: {
      margin: '0px 20px',
      fontSize: 18,
      color: theme.palette.primary,
    },
    leadInfo: {
      fontSize: 16,
      // margin: '2px 20px',
    },
    userIcon: {
      fontSize: 46,
      color: `${theme.palette.primary} !important`,
    },
    icon: {
      fontSize: 36,
      color: `${theme.palette.primary} !important`,
    },
    voipNumber: {
      width: 200,
      color: theme.palette.primary,
    },
    paper: {
      height: '100%',
      width: '100%',
      margin: 0,
      maxHeight: '100%',
      maxWidth: '100%',
      background: 'transparent',
    },
  }));

  const classes = useStyles();

  if (!ownRamal) {
    return (
      <React.Fragment>
        <CircularProgress />
        <div style={{paddingInline: 16}}>
          <span className={classes.leadInfo}>Aguarde enquanto preparamos seu Ramal</span>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {!incoming && (
        <React.Fragment>
          {/** Info button and text */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'collumn',
            }}
          >
            <Typography
              variant="h8"
              gutterBottom={true}
              style={{textAlign: 'center'}}
            >
              Sua Bina: <strong>{`${(bina && binaIsValid) ? bina : '-'}`}</strong>
            </Typography>
            <IconButton
              handleClick={(event) => {
                setHelpAnchorEl(event.currentTarget);
                setHelpOpen(true);
              }}
              alt="Como funciona?"
              style={{marginTop: -6}}
            >
              <InfoOutlined color='primary' />
            </IconButton>
          </div>
          <Popover
            id={'help'}
            open={helpOpen}
            anchorEl={helpAnchorEl}
            onClose={() => {
              setHelpAnchorEl(null);
              setHelpOpen(false);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box
              sx={{
                p: 2,
                display: 'grid',
                gridTemplateColumns: {md: '1fr 1fr'},
                gap: 2,
              }}
              style={{padding: 2}}
            >
              <Typography style={{maxWidth: 500}}>
                <strong>&gt; Para quem posso ligar?:</strong><br/>
                As ligações serão feitas para qualquer lead que esteja nos status de atendimento:&nbsp;
                {statusNamesData}, e que possua um telefone de contato (A prioridade da discadora sempre será o Telefone Whatsapp).
                <br/><br/>
                <strong>&gt; Para quantos leads posso ligar por vez?:</strong><br/>
                Para até 5 leads, quando 1 deles atender, as demais chamadas são encerradas automaticamente.
                O lead do contato que atendeu ficará selecionado na tela para você.
                <br/><br/>
                <strong>&gt; Quanto tempo a discadora chama até alguém atender?:</strong><br/>
                Até 30 segundos, depois desse tempo as ligações são abortadas.
                <br/><br/>
                <strong>&gt; De quanto em quanto tempo posso ligar para um mesmo lead?:</strong><br/>
                Sempre que a Discadora tenta ligar para um lead e não há resposta, ela poderá tentar novamente após 6 horas. 
                Este tempo é para evitar ocorrências de spam e para manter a boa reputação do remetente.
                <br/>
              </Typography>
            </Box>
          </Popover>
          {currentLeadDataTalking &&
            <div
              style={{textAlign: 'center'}}
            >
              <Typography
                variant="subtitle1"
                gutterBottom={true}
              >
                Você está falando com:
                <br/>
                <strong>
                  {`${currentLeadDataTalking.customerName} (${currentLeadDataTalking.phone})`}
                </strong>
              </Typography>
            </div>
          }
          {/** Dialer layout */}
          {!incoming && !disconnected && (
            <React.Fragment>
              <Button
                style={{
                  color: '#fff',
                  width: '100%',
                  marginTop: '0.2rem',
                  fontSize: '1rem',
                  borderRadius: '10px',
                  background:
                    switchStatus === 'ready' ? '#49C763' :
                      switchStatus === 'incoming' ? '#C7495A' :
                        switchStatus === 'unregistered' ? '#49C763' : '#FFF',
                }}
                disabled={
                  switchStatus === 'unregistered' ||
                  !leadListCount ||
                  leadListCount <= 0 ||
                  !callNumbers ||
                  regressiveCounter > 0 ||
                  leadListCount - callNumbers.freezed <= 0
                }
                onClick={
                  switchStatus === 'ready' ? createCall :
                    switchStatus === 'incoming' ? doHangup : createCall
                }
                startIcon={<CallOutlinedIcon />}
              >
                {switchStatus === 'unregistered' && (
                  <Skeleton height="36px" width="36px" style={{transform: 'scale(1)'}} />
                )}
                {switchStatus === 'ready' && !regressiveCounter && "Iniciar chamadas"}
                {switchStatus === 'incoming' && "Encerrar chamada"}
                {regressiveCounter && `Iniciando em ${regressiveCounter}...`}
              </Button>
            </React.Fragment>
          )}
          {regressiveCounter && regressiveCounter > 0 &&
            <Button
              style={{
                color: '#fff',
                width: '100%',
                marginTop: '0.5rem',
                fontSize: '1rem',
                borderRadius: '10px',
                background: '#f44336',
              }}
              onClick={() => handleCancelDialer()}
              startIcon={<CallEndIcon />}
            >
              Cancelar ligações
            </Button>
          }
          {disconnected && (
            <div
              style={{
                borderRadius: 10,
                margin: '0px 10px 10px',
                transition: 'margin .5s',
                padding: 10,
                backgroundColor: '#C7495A',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>
                {voipEmbedded
                  ? 'Conexão perdida. Feche o discador e tente novamente.'
                  : 'Conexão perdida. Tente recarregar a página.'
                }
              </span>
            </div>
          )}
          {!incoming && !disconnected && (
            <CheckBox
              label="Ligar automaticamente para os próximos 5 contatos após finalizar"
              name="autoCallNext5"
              disabled={switchStatus !== 'ready'}
              control={control}
              ref={register}
            />
          )}
          <div
            style={{
              borderRadius: 10,
              margin: '10px 0px',
              padding: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              transition: '.5s',
            }}
          >
            {switchStatus === 'incoming' && (
              <React.Fragment>
                <IconButton
                  handleClick={handleMute}
                  style={{
                    borderRadius: 40,
                    backgroundColor: mute ? '#474e54' : 'transparent',
                  }}
                >
                  {!mute
                    ? (<MicOffIcon className={classes.icon} />)
                    : (<MicOffIcon className={classes.icon} />)
                  }
                </IconButton>
                
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '90%',
                  }}
                >
                  <IconButton
                    handleClick={() => {
                      if (volume > 0) {
                        setLastVolume(volume);
                        handleVolume(0);
                      } else {
                        handleVolume(lastVolume);
                      }
                    }}
                    style={{
                      backgroundColor: volume === 0 ? '#474e54' : 'transparent',
                      padding: 8,
                    }}
                  >
                    <VolumeDown className={classes.icon} />
                  </IconButton>
                  <Slider
                    value={volume}
                    onChange={(_e, vol) => handleVolume(vol)}
                    step={10}
                    min={0}
                    max={100}
                    aria-labelledby="continuous-slider"
                    style={{
                      color: '#00000',
                      marginInline: 12,
                    }}
                  />
                  <IconButton handleClick={() => handleVolume(100)}>
                    <VolumeUp className={classes.icon} />
                  </IconButton>
                </div>
              </React.Fragment>
            )}
          </div>
          <Divider variant='middle' style={{marginTop: '10px'}}/>
          {statusNamesData &&
            <React.Fragment>
              <div
                style={{
                  marginTop: '20px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h7"
                  gutterBottom={true}
                >
                  <strong>Seus números</strong>
                </Typography>
              </div>
              {leadListCount >= 0 && 
                <Card style={{marginTop: '20px', textAlign: 'center'}}>
                  <CardContent>
                    <Typography variant="h5">
                      {leadListCount}
                    </Typography>
                    <Typography variant="caption">
                      Leads em status {statusNamesData}
                    </Typography>
                  </CardContent>
                </Card>
              }
              {callNumbers && leadListCount >= 0 &&
                <React.Fragment>
                  <Card style={{marginTop: '10px', textAlign: 'center'}}>
                    <CardContent>
                      <Typography variant="h5">
                        {callNumbers.completed}
                      </Typography>
                      <Typography variant="caption">
                        Chamadas atendidas
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card style={{marginTop: '10px', textAlign: 'center'}}>
                    <CardContent>
                      <Typography variant="h5">
                        {callNumbers.noAnswer}
                      </Typography>
                      <Typography variant="caption">
                        Chamadas sem resposta
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card style={{marginTop: '10px', textAlign: 'center'}}>
                    <CardContent>
                      <Typography
                        variant="h5"
                        color={leadListCount - callNumbers.freezed <= 0 ? "error" : ""}
                      >
                        {leadListCount - callNumbers.freezed}
                      </Typography>
                      <Typography variant="caption">
                        Leads ainda estão disponíveis para ligar
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card style={{marginTop: '10px', textAlign: 'center'}}>
                    <CardContent>
                      <Typography variant="h5">
                        {callNumbers.freezed}
                        <Tooltip
                          title="Esses são leads para os quais você já ligou recentemente, então até que se passem 6 horas desde da última ligação,
                          a discadora não poderá ligar para o lead novamente."
                          style={{marginBottom: '-2px'}}
                        >
                          <InfoOutlined color="primary" />
                        </Tooltip>
                      </Typography>
                      <Typography variant="caption">
                        Chamadas congeladas
                      </Typography>
                    </CardContent>
                  </Card>
                </React.Fragment>
              }
              {(callNumbers === null || leadListCount === null) &&
                [1, 2, 3].map(() => {
                  return (
                    <Card style={{marginTop: '10px', textAlign: 'center'}}>
                      <CardContent>
                        <Skeleton height="100px" width="100%" style={{transform: 'scale(1)'}} />
                      </CardContent>
                    </Card>
                  )
                })
              }
            </React.Fragment>
          }
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Dialer;
