import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatchPost} from 'redux/hooks/fetch';
import {Typography, Card, CardContent, makeStyles, IconButton, Tooltip, Divider} from '@material-ui/core';
import Button from 'components/buttons/Button';
import PersonIcon from '@material-ui/icons/Person';
import LinkIcon from '@material-ui/icons/Link';
import {useAppValue, useDispatchSetAppError, useDispatchSetAppInfoMesssage, useDispatchSetAppSuccessMessage, useDispatchSetAppValue} from 'redux/hooks/app';
import {useUserContext} from 'context/index';
import {timeSince} from 'utils/moment';
import Input from 'components/inputs/Input';

function PocketOfLeads() {
  // Styles
  const useStyles = makeStyles((theme) => ({
    card: {
      marginBottom: theme.spacing(1),
      height: 68,
      width: '100%',
    },
    content: {
      padding: 0,
      width: '98%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    contentFirstRow: {
      padding: 0,
      width: '100%',
      height: '15%',
      textAlign: 'right',
    },
    timeSince: {
      fontSize: 12,
      fontStyle: 'italic',
      marginRight: '5px',
    },
    contentSecondRow: {
      padding: '0px 5px 0px 5px',
      width: '100%',
      height: '85%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    icon: {
      width: '10%',
    },
    title: {
      width: '70%',
      marginLeft: '10px',
      maxWidth: '70%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    actionButton: {
      width: '20%',
      textAlign: 'center',
    },
    noLeads: {
      textAlign: 'center',
      marginTop: '50%',
      color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.secondary.light,
      fontSize: 18,
    },
  }));

  const classes = useStyles();

  const post = useDispatchPost();
  const setAppError = useDispatchSetAppError();
  const setAppInfo = useDispatchSetAppInfoMesssage();
  const setAppSuccess = useDispatchSetAppSuccessMessage();

  const history = useHistory();

  const {groupType} = useUserContext();

  const leadsWaitingOnPocket = useAppValue('leadsWaitingOnPocket');
  const setLeadsWaitingOnPocket = useDispatchSetAppValue('leadsWaitingOnPocket');

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    // Get data
    post('/lead/list-from-pocket').then((responseData) => {
      setLeadsWaitingOnPocket(responseData.data);
    }).catch((err) => {
      console.log(err);
      setAppError('Erro ao atualizar leads');
    });
    // Each 30 seconds, refresh
    let isMounted = true;
    const intervalId = setInterval(() => {
      if (!isMounted) return;
      post('/lead/list-from-pocket').then((responseData) => {
        setLeadsWaitingOnPocket(responseData.data);
      }).catch((err) => {
        console.log(err);
      });
    }, 30000);
    // Clear interval
    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, [post, setLeadsWaitingOnPocket, setAppError]);

  const handleGetLead = (leadId) => {
    // Loading
    setLoading(true);
    // Request
    post('/lead/get-from-pocket', {leadId}).then(() => {
      const newList = leadsWaitingOnPocket.filter((data) => {
        return data._id !== leadId;
      });
      setLeadsWaitingOnPocket([...newList]);
      setLoading(false);
      setAppSuccess('Lead adicionado à fila!');
    }).catch((err) => {
      // Reset loading
      setLoading(false);
      // Check if lead was already gotten by another sales
      if (typeof err === 'object' && err.status === 429) {
        setAppInfo('Este lead acaba de ser pego por outro vendedor :(');
      } else {
        setAppError('Algo deu errado ao tentar pegar o lead, tente novamente');
      }
    });
  };

  const goToLead = (leadId) => {
    if (!leadId) return;
    history.push(`/chat/${leadId}`);
  }

  // This is a simple search method (I didn`t want to make something too complex using backend here)
  const dynamicList = () => {
    if (leadsWaitingOnPocket.length < 1) return [];
    return leadsWaitingOnPocket.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase()))
  }

  const renderSearchField = () => {
    if (leadsWaitingOnPocket.length < 1) {
      return;
    }
    return (
      <Input
        name="search"
        label="Digite um nome para filtrar..."
        onChange={(e) => setSearchText(e.target.value)}
      />
    )
  }

  const renderLeadsWaiting = () => {
    // If no leads
    if (leadsWaitingOnPocket.length < 1) {
      return (
        <Typography className={classes.noLeads}>Não há leads no momento</Typography>
      )
    }
    // If so
    return dynamicList().map((data) => {
      return (
        <Card
          key={data._id}
          className={classes.card}
        >
          <CardContent className={classes.content}>
            <div className={classes.contentFirstRow}>
              <Typography className={classes.timeSince}>
                {'Há ' + timeSince(new Date(data.distributionStatusDate))}
              </Typography>
            </div>
            <div className={classes.contentSecondRow}>
              <PersonIcon />
              <Typography className={classes.title}>{data.title}</Typography>
              {groupType === 'store' &&
                <Button
                  color='primary'
                  disabled={loading}
                  handleClick={() => handleGetLead(data._id)}
                >
                  Atender
                </Button>
              }
              {groupType !== 'store' &&
                <Tooltip
                  title={'Acessar lead'}
                >
                  <IconButton edge="end" onClick={() => goToLead(data._id)}>
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              }
            </div>
          </CardContent>
        </Card>
      );
    });
  };

  return (
    <div>
      {renderSearchField()}
      <Divider variant='middle' style={{marginTop: '15px', marginBottom: '15px'}} />
      {renderLeadsWaiting()}
    </div>
  );
}

export default PocketOfLeads;
