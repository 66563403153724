/* eslint-disable import/prefer-default-export */
import {stripNonNumeric} from 'utils';

export function formatPhone(value) {
  // pt_br
  let maskedValue;
  const pureValue = stripNonNumeric(value);
  if (pureValue.length === 10) {
    maskedValue = pureValue.substr(0, 2)
      + ' ' + pureValue.substr(2, 4) + '-'
      + pureValue.substr(6, 4);
  } else if (pureValue.length === 11) {
    maskedValue = pureValue.substr(0, 2)
      + ' ' + pureValue.substr(2, 5)
      + '-' + pureValue.substr(7, 4);
  } else if (pureValue.length === 12) {
    maskedValue = '+' + pureValue.substr(0, 2)
      + ' ' + pureValue.substr(2, 2)
      + ' ' + pureValue.substr(4, 4)
      + '-' + pureValue.substr(8, 4);
  } else if (pureValue.length === 13) {
    maskedValue = '+' + pureValue.substr(0, 2)
      + ' ' + pureValue.substr(2, 2)
      + ' ' + pureValue.substr(4, 5)
      + '-' + pureValue.substr(9, 4);
  } else {
    maskedValue = value;
  }
  return maskedValue;
}

export function mask (word){
  if (!word) return '';
  return `${word.substring(0, 4)}${word.substr(4, 4).replace(/./g, "*")}`;
}
