import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {isOutsideClick} from 'utils';

import styles from './Dropdown.module.css';

function Dropdown(props) {
  const {
    autoClosing,
    children,
    className,
    hasArrow,
    label,
    positionX,
    positionY,
    selected,
    labelStyle,
    outerButtonStyle,
  } = props;

  const refDropdown = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback((e) => {
    if (autoClosing || isOutsideClick(e, refDropdown.current)) {
      setIsOpen(false);
    }
  }, [autoClosing]);

  // TODO workaround to close dropdown after select
  // was causing issues, solved in another way
  /*
  useEffect(() => {
    setIsOpen(false);
  }, [label]);
  */

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClick);
    }
    return () => {
      if (isOpen) {
        document.removeEventListener('click', handleClick);
      }
    };
  }, [handleClick, isOpen]);

  const close = () => {
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    if (!isOpen) setIsOpen(true);
  };

  return (
    <div className={cn(styles.dropdown, className)}>
      <button
        className={selected ? styles.openButtonSelected : styles.openButton}
        onClick={handleButtonClick}
        role="menu"
        type="button"
      >
        <div style={{...labelStyle}} className={styles.label}>
          <div style={{...outerButtonStyle}}>
            {label}
          </div>
        </div>
        {hasArrow ? (
          <svg className={styles.arrow}>
            <use xlinkHref="#icon-feather-chevron-down"/>
          </svg>
        ) : null}
      </button>
      {isOpen ? (
        <div
          className={cn(styles.menu, {
            [styles.menuRight]: positionX === 'right',
            [styles.menuTop]: positionY === 'top',
          })}
          ref={refDropdown}
        >
          <div onClick={close}>
            {children({closeDropdown: close})}
          </div>
        </div>
      ) : null}
    </div>
  );
}

Dropdown.propTypes = {
  autoClosing: PropTypes.bool,
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  hasArrow: PropTypes.bool,
  label: PropTypes.node.isRequired, // pode ser um jsx também, além de string
  positionX: PropTypes.oneOf(['left', 'right']),
  positionY: PropTypes.oneOf(['bottom', 'top']),
  selected: PropTypes.string,
};

Dropdown.defaultProps = {
  autoClosing: true,
  hasArrow: true,
  positionX: 'left',
  positionY: 'bottom',
};

export default Dropdown;
