import {lazy} from 'react';
import LazyComponent from 'components/LazyComponent';

export default [
  {
    component: LazyComponent(lazy(() => import('./List'))),
    path: '/survey/list',
  },
  {
    component: LazyComponent(lazy(() => import('./Update'))),
    path: '/survey/update/:id',
  },
  {
    component: LazyComponent(lazy(() => import('./Create'))),
    path: '/survey/create',
  },
  {
    component: LazyComponent(lazy(() => import('./ResponseSurvey'))),
    path: '/survey/response/:id',
  },
  {
    component: LazyComponent(lazy(() => import('./ResultSurvey'))),
    path: '/survey/result/:id',
  },
];
