/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React, {
  Fragment,
  useState,
} from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {IconButton as MaterialIconButton, createTheme, ThemeProvider, Tooltip} from '@material-ui/core';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './IconButton.module.css';

// Customize material tooltip styles
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

function IconButton(props) {
  const {
    alt,
    altPosition,
    children,
    className,
    disabled,
    handleClick,
    type,
    variant,
    color,
    size,
    ariaLabel,
    edge,
    ...htmlProps
  } = props;

  const useStyles = makeStyles((theme) => ({
    icon: {
      color: theme.palette.type === 'dark'
      ? theme.palette.supportOne
      : theme.palette.supportThree,
    },
  }));

  const classes = useStyles();

  // Customized palettes
  const theme = createTheme({
    palette: {
      primary: {
        main: '#454955',
        dark: '#2C2E35',
      },
      secondary: {
        main: '#FFF',
        dark: '#F3F3F3',
      },
    },
  });

  // Control tooltip
  const [isOpen, setIsOpen] = useState(false);

  const handleClickButton = (e) => {
    if (handleClick) handleClick(e);
  };

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <LightTooltip
          open={alt && isOpen ? true : false}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          title={alt || ''}
          placement={altPosition}
        >
          <MaterialIconButton
            {...htmlProps}
            disableRipple={true}
            disabled={disabled}
            color={color ? color : 'primary'}
            variant={variant}
            size={size}
            className={cn(className, classes.icon, {
              [styles.primary]: color === 'primary',
              [styles.gradient]: color === 'gradient',
              [styles.secondary]: color === 'secondary',
            })}
            onClick={handleClickButton}
            aria-label={ariaLabel}
            edge={edge}
            type={type}
          >
            {children}
          </MaterialIconButton>
        </LightTooltip>
      </ThemeProvider>
    </Fragment>
  );
}

IconButton.propTypes = {
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  edge: PropTypes.string,
  handleClick: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  type: 'button',
};

export default IconButton;
